import { DateInput, DateTime, Interval } from 'luxon';

export const generateDateStringFromTwoDates = (startDate: DateTime, endDate: DateTime, dString: string = 'd') => {
  // If dates are same day, return date
  if (startDate.day === endDate.day) {
    return startDate.toFormat(`${dString} LLL`, { locale: 'en-GB' });
  }

  // If dates are different years, return full date
  if (startDate.year !== endDate.year) {
    return `${startDate.toFormat(`${dString} LLL`)} - ${endDate.toFormat(`${dString} LLL`, { locale: 'en-GB' })}`;
  }

  // If dates are different months, return full date
  if (startDate.month !== endDate.month) {
    return `${startDate.toFormat(`${dString} LLL`)} - ${endDate.toFormat(`${dString} LLL`, { locale: 'en-GB' })} ${startDate.year}`;
  }

  // If dates are same month, return day range
  if (startDate.month === endDate.month) {
    return `${startDate.day}-${endDate.day} ${startDate.toFormat(`LLL`, { locale: 'en-GB' })}`;
  }
};

const textConfig: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

const monthConfig: Intl.DateTimeFormatOptions = {
  month: 'long',
};

export const formatDateTime = (date: DateTime) => date.toFormat('yyyy-MM-dd');

const formatDate = (data: string | number | Date, config: Intl.DateTimeFormatOptions | undefined) => {
  return data ? new Intl.DateTimeFormat('en-GB', config).format(new Date(data)) : '-';
};

export const dayMonthYear = (data: string | number | Date) => formatDate(data, textConfig);
export const longMonth = (data: string | number | Date) => formatDate(data, monthConfig);

export const getDateRange = (start: DateInput, end: DateInput) => {
  const interval = Interval.fromDateTimes(start, end);

  const ds = interval.splitBy({ day: 1 });
  const days = ds.map((day) => formatDateTime(day.start));
  return days;
};

export const getThisWeekThisMonthThisYearOrEarlier = (dateString: string) => {
  const today = DateTime.local();
  const thisWeek = today.weekNumber;
  const thisMonth = today.month;
  const thisYear = today.year;

  const date = DateTime.fromISO(dateString);
  const week = date.weekNumber;
  const month = date.month;
  const year = date.year;

  if (year === thisYear) {
    if (month === thisMonth) {
      if (week === thisWeek) {
        return 'This week';
      }
      
      return 'This month';
    }

    return 'This year';
  }

  return 'Earlier';
}
