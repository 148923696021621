import Card from 'components/parts/Card';
import Image from 'components/parts/Image';
import { useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'utils/useNavigate';
import { colors, fonts } from 'utils/constants';
import { dayMonthYear, getThisWeekThisMonthThisYearOrEarlier, longMonth } from 'utils/dateHelpers';
import Note from 'interfaces/Note';
import { useGet } from 'repository/useGet';
import { getNotes, toggleNotePinned } from 'repository/notes';
import { usePost } from 'repository/usePost';
import { useQueryClient } from 'react-query';
import Column from 'components/parts/Column';
import Row from 'components/parts/Row';
import { useStore } from 'utils/store';
import { PinNoteButtonTagManagerArgs, pushTagManagerLayer } from 'utils/tagManagerArgs';


type Props = {};

const style = StyleSheet.create({
  base: {},
  wrapper: {
    width: '100%',
    paddingTop: '2em',
    marginBottom: '100px',
  },
  blueBackground: {
    width: '100%',
    height: '171px',
    backgroundColor: colors.core.cyan10,
    position: 'absolute',
    top: 0,
    zIndex: 0,
  },
  headWrapper: {
    padding: '2em',
  },
});

const Notes: React.FC<Props> = () => {
  const {
    noteId: { setNoteId }
  } = useStore();
  const queryClient = useQueryClient();

  const { data: notes } = useGet(['getNotes'], () => getNotes(), {
    onSuccess: data => console.log('yay my notes:', data),
  });

  const toggleNotePinnedHandler = async (guid: string, noteTitle: string, pinned: boolean) => {
    console.log(guid, pinned);
    await toggleNotePinned(guid, pinned);
    pushTagManagerLayer(PinNoteButtonTagManagerArgs(noteTitle, !pinned))
      
    queryClient.invalidateQueries(['getNotes']);
  };

  type NoteSpan = {
    "This week": Note[];
    "This month": Note[];
    "This year": Note[];
    "Earlier": Note[];
  }

  //STATES
  const [pinnedNotes, setPinnedNotes] = useState(new Map<string, Note[]>());

  const [unpinnedNotes, setUnpinnedNotes] = useState<NoteSpan>({ "This week": [], "This month": [], "This year": [], "Earlier": [] });

  const navigate = useNavigate();

  const sortNotes = (notes: Note[]) => notes.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  const groupNotes = useCallback(
    (notes: Note[]) => {
      return sortNotes(notes).reduce(
        (notesGroup, note) => notesGroup.set(note.createdAt, [...(notesGroup.get(note.createdAt) || []), note]),
        new Map<string, Note[]>()
      );
    },
    [],
  );

  // HOOKS

  useEffect(() => {
    if (notes) {
      const [pinned, unpinned] = notes.reduce(
        ([a, b]: [Note[], NoteSpan], { pinned }, idx) => {
          if (pinned) {
            a.push(notes[idx]);
          } else {
            const span = getThisWeekThisMonthThisYearOrEarlier(notes[idx].createdAt);
            b[span] = [notes[idx], ...b[span]];
          }

          return [a, b];
        },
        [[], { "This week": [], "This month": [], "This year": [], "Earlier": []}]
      );
      
      setPinnedNotes(groupNotes(pinned));
      setUnpinnedNotes(unpinned);


    }
  }, [notes, groupNotes]);

  return (
    <ScrollView style={style.base}>
      <View style={style.wrapper}>
        <View style={style.blueBackground}></View>
          <View style={style.headWrapper}>
            <Text style={{ ...fonts.headline1, color: colors.grayScale.darkNeutral100, marginBottom: '-0.5em' }}>Notes</Text>
          </View>
        <>
          {pinnedNotes.size > 0 && (
            <View style={{ paddingLeft: '1em', paddingBottom: '1em' }}>
              <Text style={{ ...fonts.smallBoldHeader, color: colors.grayScale.darkNeutral100 }}>
                Pinned
              </Text>
            </View>
          )}
          
          {Array.from(pinnedNotes).map(([createdAt, notes], idx) => {
            return (
              <Column key={`month_${idx}`}>
                {notes.map((item) => {
                  return (
                    <Card style={{ paddingBottom: '1em', marginBottom: '1em' }} key={item.id}>
                      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Text>By {item.createdBy}</Text>
                        <TouchableOpacity onPress={() => toggleNotePinnedHandler(item.guid, item.title, item.pinned)}>
                          <Image imageName="pinnedIcon" style={{ width: '19px', height: '17px' }} />
                        </TouchableOpacity>
                      </Row>

                      <TouchableOpacity
                        onPress={() => {
                          setNoteId(item.guid);
                          navigate(`/notes/${item.guid}`, 'Note');
                        }}>
                        <Text style={{ ...fonts.headline3, color: colors.grayScale.darkNeutral100 }}>{item.title}</Text>
                      </TouchableOpacity>
                      <Text>Created {dayMonthYear(item.createdAt)}</Text>
                    </Card>
                  );
                })}
              </Column>
            );
          })}

          {Object.keys(unpinnedNotes).map(spanKey => {
            const spanNotes = unpinnedNotes[spanKey as keyof NoteSpan];

            if (spanNotes.length === 0) {
              return null;
            }

            console.log('spanNotes', spanNotes)

            return (
              <div key={`month_${spanKey}`}>
                <View>
                  <Text style={{ ...fonts.smallBoldHeader, color: colors.grayScale.darkNeutral100, paddingLeft: '1em', paddingBottom: '1em' }}>
                    {spanKey}
                  </Text>
                </View>

                {spanNotes.map((item) => {
                  return (
                    <Card style={{ paddingBottom: '1em', marginBottom: '1em' }} key={item.id}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Text>By {item.createdBy}</Text>
                        <TouchableOpacity onPress={() => toggleNotePinnedHandler(item.guid, item.title, item.pinned)}>
                          <Image imageName="unpinnedIcon" style={{ width: '19px', height: '17px' }} />
                        </TouchableOpacity>
                      </div>

                      <TouchableOpacity
                        onPress={() => {
                          setNoteId(item.guid);
                          navigate(`/notes/${item.guid}`, 'Note');
                        }}>
                        <Text style={{ ...fonts.headline3, color: colors.grayScale.darkNeutral100 }}>{item.title}</Text>
                      </TouchableOpacity>
                      <Text>Created {dayMonthYear(item.createdAt)}</Text>
                    </Card>
                  );
                })}
              </div>
            );
          })};
        </>
      </View>
    </ScrollView>
  );
};

export default Notes;
