import { ReportTypes } from 'enums';
import { Index } from 'models/Index/Index.model';
import { createContext, PropsWithChildren, useContext, useState } from 'react';

type ComponentWithChildProps = PropsWithChildren<{}>;

export const StoreContext = createContext({
  reportPopup: { isReportPopupVisible: false, showReportPopup: () => {}, hideReportPopup: () => {} },
  publishPopup: {
    isPublishPopupVisible: false,
    showPublishPopup: (indexId: string, week: number) => {},
    hidePublishPopup: () => {},
  },
  indexSettingsMenu: {
    isIndexSettingsMenuVisible: false,
    showIndexSettingsMenu: (indexId: string) => {},
    hideIndexSettingsMenu: () => {},
  },
  noteSettingsMenu: {
    isNoteSettingsMenuVisible: false,
    showNoteSettingsMenu: () => {},
    hideNoteSettingsMenu: () => {},
  },
  confirmSignoutDialog: {
    isConfirmSignoutDialogVisible: false,
    showConfirmSignoutDialog: () => {},
    hideConfirmSignoutDialog: () => {},
  },
  confirmDeleteIndexDialog: {
    isConfirmDeleteIndexDialogVisible: false,
    showConfirmDeleteIndexDialog: () => {},
    hideConfirmDeleteIndexDialog: () => {},
  },
  confirmDeleteNoteDialog: {
    isConfirmDeleteNoteDialogVisible: false,
    showConfirmDeleteNoteDialog: () => {},
    hideConfirmDeleteNoteDialog: () => {},
  },
  reportType: { reportType: ReportTypes.yesterday, setReportType: (value: number) => {} },
  indexId: { indexId: '', setIndexId: (value: string) => {} },
  noteId: { noteId: '', setNoteId: (value: string) => {} },
  noteTitle: { noteTitle: '', setNoteTitle: (value: string) => {} },
  noteStatus: { noteStatus: '', setNoteStatus: (value: string) => {} },
  noteAssociatedSite: { noteAssociatedSite: '', setNoteAssociatedSite: (value: string) => {} },
  week: { week: 0, setWeek: (value: number) => {} },
  indexName: { indexName: '', setIndexName: (value: string) => {} },
});

const StoreProvider = ({ children }: ComponentWithChildProps) => {
  const store = useProvideStore();

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

const useProvideStore = () => {
  const [isReportPopupVisible, setIsReportPopupVisible] = useState(false);
  const [isPublishPopupVisible, setIsPublishPopupVisible] = useState(false);
  const [isIndexSettingsMenuVisible, setIsIndexSettingsMenuVisible] = useState(false);
  const [isNoteSettingsMenuVisible, setIsNoteSettingsMenuVisible] = useState(false);
  const [isConfirmSignoutDialogVisible, setIsConfirmSignoutDialogVisible] = useState(false);
  const [isConfirmDeleteIndexDialogVisible, setIsConfirmDeleteIndexDialogVisible] = useState(false);
  const [isConfirmDeleteNoteDialogVisible, setIsConfirmDeleteNoteDialogVisible] = useState(false);
  const [reportType, setReportTypeValue] = useState(ReportTypes.yesterday);
  const [indexId, setIndexId] = useState('');
  const [noteId, setNoteId] = useState('');
  const [noteTitle, setNoteTitle] = useState('');
  const [noteStatus, setNoteStatus] = useState('');
  const [noteAssociatedSite, setNoteAssociatedSite] = useState('');
  const [indexName, setIndexName] = useState('');
  const [indexes, setIndexes] = useState<Index[]>([]);
  const [week, setWeek] = useState<number>(0);

  function showReportPopup() {
    setIsReportPopupVisible(true);
  }

  function hideReportPopup() {
    setIsReportPopupVisible(false);
  }

  function showPublishPopup(indexId: string, week: number) {
    setIndexId(indexId);
    setWeek(week);
    setIsPublishPopupVisible(true);
  }

  function hidePublishPopup() {
    setIsPublishPopupVisible(false);
  }

  function showIndexSettingsMenu(indexId: string) {
    setIndexId(indexId);
    setIsIndexSettingsMenuVisible(true);
  }

  function hideIndexSettingsMenu() {
    setIsIndexSettingsMenuVisible(false);
  }

  function showNoteSettingsMenu() {
    setIsNoteSettingsMenuVisible(true);
  }

  function hideNoteSettingsMenu() {
    setIsNoteSettingsMenuVisible(false);
  }

  function showConfirmSignoutDialog() {
    setIsConfirmSignoutDialogVisible(true);
  }

  function hideConfirmSignoutDialog() {
    setIsConfirmSignoutDialogVisible(false);
  }

  function showConfirmDeleteIndexDialog() {
    setIsConfirmDeleteIndexDialogVisible(true);
  }

  function hideConfirmDeleteIndexDialog() {
    setIsConfirmDeleteIndexDialogVisible(false);
  }

  function showConfirmDeleteNoteDialog() {
    setIsConfirmDeleteNoteDialogVisible(true);
  }

  function hideConfirmDeleteNoteDialog() {
    setIsConfirmDeleteNoteDialogVisible(false);
  }

  function setReportType(value: number) {
    setReportTypeValue(value);
  }

  return {
    reportPopup: { isReportPopupVisible, showReportPopup, hideReportPopup },
    publishPopup: { isPublishPopupVisible, showPublishPopup, hidePublishPopup },
    indexSettingsMenu: { isIndexSettingsMenuVisible, showIndexSettingsMenu, hideIndexSettingsMenu },
    noteSettingsMenu: { isNoteSettingsMenuVisible, showNoteSettingsMenu, hideNoteSettingsMenu },
    confirmSignoutDialog: { isConfirmSignoutDialogVisible, showConfirmSignoutDialog, hideConfirmSignoutDialog },
    confirmDeleteIndexDialog: { isConfirmDeleteIndexDialogVisible, showConfirmDeleteIndexDialog, hideConfirmDeleteIndexDialog },
    confirmDeleteNoteDialog: { isConfirmDeleteNoteDialogVisible, showConfirmDeleteNoteDialog, hideConfirmDeleteNoteDialog },
    reportType: { reportType, setReportType },
    indexId: { indexId, setIndexId },
    noteId: { noteId, setNoteId },
    noteTitle: { noteTitle, setNoteTitle },
    noteStatus: { noteStatus, setNoteStatus },
    noteAssociatedSite: { noteAssociatedSite, setNoteAssociatedSite },
    week: { week, setWeek },
    indexName: { indexName, setIndexName },
    indexes: { indexes, setIndexes },
  };
};

export const useStore = () => useContext(StoreContext);

export default StoreProvider;
