import Image from 'components/parts/Image';
import { useUserState } from 'contexts/UserContext';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'utils/useNavigate';
import { getIndex } from 'repository';
import { useGet } from 'repository/useGet';
import { colors, fonts } from 'utils/constants';
import { useStore } from 'utils/store';
import { pushTagManagerLayer, QuickActionTagManagerArgs } from 'utils/tagManagerArgs';
import IndexDetailHead from './Head';
import IndexDetailOverview from './Overview';

type IndexDetailProps = {};

const IndexDetail: React.FC<IndexDetailProps> = observer(() => {
  const style = StyleSheet.create({
    base: {
      paddingBottom: '24px',
    },
    topWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: colors.grayScale.lightNeutral20,
      padding: '2em',
    },
    backButtonText: {
      ...fonts.medium,
      color: colors.core.blue,
    },
    headWrapper: {
      backgroundColor: colors.grayScale.lightNeutral20,
      padding: '2em ',
      paddingTop: 0,
      paddingBottom: '0em',
    },
    overview: {
      marginTop: '2em',
      width: '100%',
    },
  });

  const { indexId } = useParams();
  const user = useUserState();

  const userId = user.id;

  const navigate = useNavigate();
  const {
    indexSettingsMenu: { showIndexSettingsMenu },
    indexId: { setIndexId },
    indexName: { setIndexName },
  } = useStore();

  const { data: index } = useGet(['getIndex', indexId], () => getIndex(indexId || ''), {
    onSuccess: (data) => {
      console.log(data);
      if (!!indexId) setIndexId(indexId);
      setIndexName(data.indexName);
    },
  });

  useEffect(() => {
    setIndexName(index ? index.indexName : '');
  }, [index]);

  if (indexId === undefined) {
    return <Text>Missing index ID</Text>;
  }

  if (index === undefined) {
    return <Text>Loading index...</Text>;
  }

  // format date as YYYY-MM-DD, HH:MM
  const isd = new Date(index.createdAt);
  const indexStartDate = `${isd.getFullYear()}/${(isd.getMonth() + 1).toString().padStart(2, '0')}/${isd
    .getDate()
    .toString()
    .padStart(2, '0')}, ${isd.getHours().toString().padStart(2, '0')}:${isd.getMinutes().toString().padStart(2, '0')}`;

  let redDispenserCount = index.dispensers.empty;
  let greenDispenserCount = index.dispensers.full;

  const indexSettingsButtonDataLayer = QuickActionTagManagerArgs(index.indexName, index.guid);

  return (
    <ScrollView style={style.base}>
      <View style={style.topWrapper}>
        <TouchableOpacity
          onPress={() => {
            navigate('/', 'Dashboard');
          }}>
          <Image imageName="backIcon" style={{ width: '8.66px', height: '16px' }} />
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            pushTagManagerLayer(indexSettingsButtonDataLayer);
            showIndexSettingsMenu(indexId);
          }}>
          <Image imageName="moreIcon" style={{ width: '32px', height: '8px' }} />
        </TouchableOpacity>
      </View>

      <View style={style.headWrapper}>
        <IndexDetailHead
          locationName={index.locationName || ''}
          title={index.indexName}
          startDate={indexStartDate}
          lastUpdate={DateTime.now().toFormat('dd/LL/yyyy, HH:mm')}
          todayVisitors={index.todayVisitors}
          yesterdayVisitors={index.yesterdayVisitors}
          dispenserIsEmpty={true}
          yesterdayProgress={index.yesterdayProgress}
          todayProgress={index.todayProgress}
          donutSpanStart="00"
          donutSpanEnd="14:30"
          greenDispenserCount={greenDispenserCount}
          redDispenserCount={redDispenserCount}
          greenThreshold={index.greenThreshold}
          yellowThreshold={index.yellowThreshold}
        />
      </View>

      <View style={style.overview}>
        <IndexDetailOverview indexId={indexId} indexName={index.indexName} greenThreshold={index.greenThreshold} yellowThreshold={index.yellowThreshold} />
      </View>
    </ScrollView>
  );
});

export default IndexDetail;
