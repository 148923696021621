import { useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, Text, View, ViewStyle, PanResponder, Animated } from "react-native";
import { colors, fonts } from "utils/constants";

interface SliderProps {
  style?: ViewStyle,
  threshold: number,
  setThreshold: (value: number) => void,
  error: boolean,
}

const Slider = ({error, style, threshold, setThreshold}: SliderProps) => {

  const sliderWidth = 300;
  const tickWidth = sliderWidth / 10;

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponder: () => true,
    
    onPanResponderMove: (e, {dx, dy}) => {
      if (Math.abs(dx) < tickWidth) return;

      let leftThresholdValue = threshold + Math.round(dx / tickWidth);

      if (leftThresholdValue > 10) {
        leftThresholdValue = 10;
      } else if (leftThresholdValue < 1) {
        leftThresholdValue = 1;
      }

      setThreshold(leftThresholdValue);
    },

    onPanResponderStart(e, { dx, dy }) {
      console.log(dx);
      if (Math.abs(dx) < tickWidth) return;


      let leftThresholdValue = threshold + Math.round(dx / tickWidth);

      if (leftThresholdValue > 10) {
        leftThresholdValue = 10;
      } else if (leftThresholdValue < 1) {
        leftThresholdValue = 1;
      }

      setThreshold(leftThresholdValue);
    },
  });

  const ticks = [
    -15, 17, 50, 83, 118, 150, 183, 217, 252, 280, 
  ]


  const styles = StyleSheet.create({
    base: {
      ...style,
      width: `${sliderWidth}px`,
      height: '6px',
      borderRadius: 30,
      // backgroundColor: colors.core.green,
      backgroundColor: colors.grayScale.darkNeutral10,
      // borderWidth: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    handle: {
      width: '30px',
      height: '30px',
      borderRadius: 30,
      position: 'absolute',
      top: -13,
      left: ticks[threshold - 1],
      backgroundColor: error ? colors.highlights.red : colors.core.blue,
      zIndex: 1,
    },
    tick: {
      width: '2px',
      height: '7px',
      borderRadius: 5,
      backgroundColor: colors.grayScale.lightNeutral0,
    },
    fill: {
      width: `${ticks[threshold - 1]}px`,
      height: '6px',
      backgroundColor: error ? colors.highlights.red : colors.core.blue,
      position: 'absolute',
      top: 0,
      left: 0,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      zIndex: 0,
    },
    container: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    thresholdContainer: {
      marginLeft: '30px',
      borderWidth: 1,
      borderColor: colors.grayScale.lightNeutral60,
      justifyContent: 'center',
      alignItems: 'center',
      width: '50px',
      height: '50px'
    },
    thresholdText: {
      ...fonts.bold, 
    },
    tickArea: {
      height: '6px',
      width: '20px',
    }
  });

  return (
    <View style={styles.container}>
      <View style={styles.base}>
        <View {...panResponder.panHandlers} style={styles.handle} />
        <View style={styles.fill} />
        {Array.from(Array(10)).map((_, i) => <View key={`slider_tick_${i}`} style={styles.tick} {...panResponder.panHandlers} onTouchStart={() => setThreshold(i + 1)} />)}
        {Array.from(Array(10)).map((_, i) => <View key={`slider_tick_click_area${i}`} style={{ position: 'absolute', width: '30px', height: '20px', left: ticks[i] + 'px' }} {...panResponder.panHandlers} onTouchStart={() => setThreshold(i + 1)} />)}
      </View>
    </View>
  );
}

export default Slider;