import { AxiosError } from 'axios';
import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from 'react-query';

// import { useAuthState } from 'contexts/auth/AuthContext';

export function useGet<TData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TData>,
  options?: UseQueryOptions<TData, AxiosError, TData> | undefined
) {
  // const { sessionRefresh } = useAuthState();

  return useQuery(
    queryKey,
    (context) => {
      // sessionRefresh();
      return queryFn(context);
    },
    {
      ...options,
    }
  );
}
