import Image from 'components/parts/Image';
import { useUserState } from 'contexts/UserContext';
import { DateFormats, Resolutions } from 'enums';
import { DateTime } from 'luxon';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { colors, fonts } from 'utils/constants';
import { pushTagManagerLayer, ToggleOverviewLeftTagManagerArgs, ToggleOverviewRightTagManagerArgs } from 'utils/tagManagerArgs';

interface ArrowSelectorProps {
  indexName: string;
  indexId: string;
  setNextDate: () => void;
  setPreviousDate: () => void;
  date: DateTime;
  resolution: Resolutions;
  nextIsDisabled: boolean;
  previousIsDisabled?: boolean;
}

const style = StyleSheet.create({
  base: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  icon: {
    width: '50px',
    height: '50px',
  },
});

const ArrowSelector = ({ indexName, indexId, setNextDate, setPreviousDate, date, resolution, nextIsDisabled, previousIsDisabled }: ArrowSelectorProps) => {
  let dateLabel = date.toFormat(DateFormats.day, { locale: 'en-GB' });
  const user = useUserState();

  const userId = user.id;

  switch (resolution) {
    case Resolutions.day:
      dateLabel = date.toFormat(DateFormats.day, { locale: 'en-GB' });
      break;
    case Resolutions.week:
      dateLabel = `Week ${date.toFormat(DateFormats.week)}`;
      break;
    case Resolutions.month:
      dateLabel = date.toFormat(DateFormats.month, { locale: 'en-GB' });
      break;
    case Resolutions.year:
      dateLabel = date.toFormat(DateFormats.year);
      break;
  }

  const barchartLeftDataLayer = ToggleOverviewLeftTagManagerArgs(indexName, indexId);
  const barchartRightDataLayer = ToggleOverviewRightTagManagerArgs(indexName, indexId);

  return (
    <View style={style.base}>
      <TouchableOpacity
        onPress={() => {
          pushTagManagerLayer(barchartLeftDataLayer);
          setPreviousDate();
        }}>
        <Image imageName={`previousIconCircle${previousIsDisabled ? '-disabled' : ""}`} style={style.icon} />
      </TouchableOpacity>

      <Text style={{ ...fonts.bold, color: colors.core.blue }}>{dateLabel}</Text>

      <TouchableOpacity
      disabled={nextIsDisabled}
        onPress={() => {
          pushTagManagerLayer(barchartRightDataLayer);
          setNextDate();
        }}>
        <Image imageName={`nextIconCircle${nextIsDisabled ? '-disabled' : ""}`} style={style.icon} />
      </TouchableOpacity>
    </View>
  );
};

export default ArrowSelector;
