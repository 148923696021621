import axios from 'axios';
import { User } from 'interfaces/User.model';
import { Site } from 'models/Index/Site.model';
import { clearState, saveState } from 'utils/storage';

type Login = {
  username: string;
  password: string;
};

type Token = {
  token: string;
};

// POST
export const login = async (login: Login): Promise<Token> => {
  const res = await axios
    .post(
      `/login`,
      { Username: login.username, Password: login.password } // this is super scuffed
    )
    .then((res) => {
      if (res.data.value === undefined) {
        throw new Error('Invalid credentials');
      }
      
      saveState({ key: 'token', storage: 'local', state: res.data.value.token });
      console.log(res.data.value.token);
      // axios.defaults.headers.common['Authorization'] = `${res.data}`; // does not add to axios headers for some reason
      window.location.href = '/'; // scuffed lol

      return res;
    }) // get / set user data here?
    .catch((err) => {
      throw err;
    });
  
  return res.data;
};

export const logout = async () => {
  // const res = await axios.post(`/logout`);
  delete axios.defaults.headers.common['Authorization'];
  clearState({ key: 'token', storage: 'local' });
  window.location.href = '/'; // scuffed lol
  // return res.data;
};

export const getUser = async (id: string): Promise<User> => {
  const res = await axios.post(`/user/${id}`);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
};

export const getUserSites = async (): Promise<Site[]> => {
  const res = await axios.get(`/usersites`);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
};
