import Button from 'components/parts/Button';
import Image from 'components/parts/Image';
import TextInput from 'components/parts/TextInput';
import { useUserState } from 'contexts/UserContext';
import { useState } from 'react';
import TagManager from 'react-gtm-module';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useLocation } from 'react-router-dom';
import { login } from 'repository/account';
import { colors, fonts } from 'utils/constants';
import history from 'utils/history';
import { loginTagManagerArgs, pushTagManagerLayer } from 'utils/tagManagerArgs';

const Login = () => {
  const style = StyleSheet.create({
    base: {
      height: '100vh',
      width: '100%',
    },
    head: {
      width: '100%',
      height: '65%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.core.blue,
    },
    body: {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '1em',
      padding: '1em',
    },
    appNameTitle: {
      ...fonts.headline4,
      color: colors.core.cyan10,
      marginTop: '2em',
    },
    bodyTitle: {
      ...fonts.headline3,
      color: colors.grayScale.darkNeutral100,
      marginTop: '1em',
    },
    instructionText: {
      ...fonts.paragraph,
      color: colors.grayScale.darkNeutral100,
      padding: '1em',
      textAlign: 'center',
    },
  });

  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [credentialsError, setCredentialsError] = useState<boolean>(false);
  const [unknownError, setUnknownError] = useState<boolean>(false);

  const user = useUserState();

  const userId = user.id;

  TagManager.dataLayer({
    dataLayer: {
      event: 'login',
      method: 'tork_vision_account',
      userId: userId,
    },
    dataLayerName: 'login',
  });

  const startLogin = async () => {
    try {
      const res = await login({ username: username, password: password });
    } catch (error: any) {
      console.log(error.message);
      if (error.message === 'Request failed with status code 500') {
        setUnknownError(true);
      } else {
        setUnknownError(false);
      }

      if (error.message === 'Invalid credentials') {
        setCredentialsError(true);
      } else {
        setCredentialsError(false);
      }
    }
  };

  // if (!userId) {
  //   return <Navigate to="/" />;
  // }

  const loginButtonDataLayer = loginTagManagerArgs();

  return (
    <ScrollView style={style.base}>
      <View style={style.head}>
        <Image imageName="torkLogo" style={{ width: '160px', height: '87px' }} />
        <Text style={style.appNameTitle}>Hand Hygiene App</Text>
      </View>

      <View style={style.body}>
        <Text style={style.bodyTitle}>Hello!</Text>
        <Text style={style.instructionText}>Sign in with your credentials</Text>

        {unknownError && (
          <Text style={{ ...fonts.bold, color: colors.highlights.red }}>
            An unknown error has occurred. Please try again or contact the administrator.
          </Text>
        )}

        {credentialsError && (
          <Text style={{ ...fonts.bold, color: colors.highlights.red }}>
            The username or password you entered is incorrect.
          </Text>
        )}

        <View style={style.body}>
          <TextInput
            style={style.bodyTitle}
            placeholder="Username"
            value={username}
            error={credentialsError}
            setValue={(value: string) => setUserName(value)}
          />
        </View>

        <View style={style.body}>
          <TextInput
            style={style.bodyTitle}
            placeholder="Password"
            secureTextEntry={true}
            value={password}
            error={credentialsError}
            setValue={(value: string) => setPassword(value)}
          />
        </View>
        
        <View style={{ marginBottom: '100px' }}>
          <Button
            onPress={() => {
              pushTagManagerLayer(loginButtonDataLayer);
              startLogin();
            }}
            buttonText="Sign in"
            style={{ width: '165px' }}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default Login;
