import { useState } from "react";
import { ScrollView, StyleSheet, Text, TouchableHighlight, TouchableOpacity, View } from "react-native";
import { colors, fonts } from "utils/constants";

interface DropdownProps<OptionsType> {
  selectedOption: OptionsType;
  setSelectedOption: (value: OptionsType) => void;
  defaultOption: OptionsType;
  
  options: {
    label: string;
    value: OptionsType;
  }[]
}

function Dropdown<OptionsType>({ setSelectedOption, selectedOption, options, defaultOption }: DropdownProps<OptionsType>) {
  const [droppedDown, setDroppedDown] = useState(false);

  const style = StyleSheet.create({
    base: {
      marginTop: '0.5em',
      zIndex: 5,
    },
    dropdownContainer: {
      maxHeight: '240px',
      borderWidth: droppedDown ? 2 : 0,
      borderTopWidth: 0,
      borderColor: colors.core.cyan,
      backgroundColor: colors.grayScale.lightNeutral0,
    },
    dropdownTextContainer: {
      height: '48px',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    dropdownText: {
      ...fonts.paragraph,
    },
    formFieldArrowContainer: {
      position: 'absolute',
      right: 0,
      borderColor: droppedDown ? colors.core.cyan : colors.core.blue,
      borderWidth: 2,
      backgroundColor: colors.core.blue,
      width: '48px',
      height: '48px',
      borderRadius: 3,
      justifyContent: 'center',
      alignItems: 'center',
    },
    reversedFormFieldArrowContainer: {
      position: 'absolute',
      right: 0,
      borderColor: droppedDown ? colors.core.cyan : colors.core.blue,
      borderWidth: 2,
      backgroundColor: colors.core.blue,
      width: '48px',
      height: '48px',
      borderRadius: 3,
      justifyContent: 'center',
      alignItems: 'center',
      transform: [{ rotateZ: '180deg'}],
    },
    formFieldArrowLineLeft: {
      width: '2px',
      height: '12px',
      backgroundColor: colors.grayScale.lightNeutral0,
      transform: [{ rotateZ: '-45deg'}],
      position: 'absolute',
      marginLeft: -8,
    },
    formFieldArrowLineRight: {
      width: '2px',
      height: '12px',
      backgroundColor: colors.grayScale.lightNeutral0,
      transform: [{ rotateZ: '45deg'}],
      position: 'absolute',
      marginLeft: 8,
    },
    floatingContainer: {
      position: 'absolute',
      width: '100%',
      top: '48px',
      backgroundColor: colors.grayScale.lightNeutral0,
      overflow: 'hidden',
      height: droppedDown ? 'auto' : 0,
    },
    formField: {
      borderWidth: 2,
      borderColor: droppedDown ? colors.core.cyan : colors.core.blue,
      borderRightWidth: 0,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      width: '100%',
      height: '48px',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: '1em',
    },
  });
  
  return (
    <View style={style.base}>
      <TouchableOpacity style={style.formField} onPress={() => setDroppedDown(!droppedDown)}>
        <Text>{options.find(option => option.value === selectedOption)?.label || 'Select a site'}</Text>
        <View style={droppedDown ? style.reversedFormFieldArrowContainer : style.formFieldArrowContainer}>
          <View style={style.formFieldArrowLineLeft}></View>
          <View style={style.formFieldArrowLineRight}></View>
        </View>
      </TouchableOpacity>

      <View style={style.floatingContainer}>
        <ScrollView style={style.dropdownContainer}>

          <TouchableHighlight key='dropdown_option_default_default_key' activeOpacity={0.5} underlayColor={colors.core.cyan10} onPress={() => { setSelectedOption(defaultOption); setDroppedDown(false);}} style={style.dropdownTextContainer}>
            <Text style={style.dropdownText}>Select a site</Text>
          </TouchableHighlight>

          {options.map((option, i) => {
            const listItemProps = {
              key: `dropdown_option_${option}_${i}_key`,
              activeOpacity: 0.5,
              underlayColor: colors.core.cyan10,
              onPress: () => { setSelectedOption(option.value); setDroppedDown(false); },
              style: style.dropdownTextContainer
            }

            return (
              <TouchableHighlight {...listItemProps}>
                <Text style={style.dropdownText}>{option.label}</Text>
              </TouchableHighlight>
            );
          })}
        </ScrollView>
      </View>
    </View>
  );
}

export default Dropdown;