import Column from 'components/parts/Column';
import Row from 'components/parts/Row';
import { colors, fonts } from 'utils/constants';
import { StyleSheet, Text } from 'react-native';
import Image from 'components/parts/Image';
import { Info } from 'luxon';

export type Report = {
  date: string;
  icon: string;
};

type WeeklyReportProps = {
  report: Report[];
};

const WeeklyReport: React.FC<WeeklyReportProps> = ({ report }) => {
  const styles = StyleSheet.create({
    starText: {
      ...fonts.paragraph,
      marginTop: '0.5em',
      color: colors.grayScale.darkNeutral80,
    },
  });

  return (
    <Row style={{ justifyContent: 'space-around', marginTop: '24px', maxWidth: '250px' }}>
      {report.map((day, i) => {
        return (
          <Column key={`weekly_report_${i}`} style={{ alignItems: 'center', width: '36px' }}>
            <Image imageName={day.icon} style={{ width: '24px', height: '24px' }} />

            <Text style={styles.starText}>{Info.weekdays('short', { locale: 'en-GB'})[i]}</Text>
          </Column>
        );
      })}
    </Row>
  );
};

export default WeeklyReport;
