import TabsHeader from 'components/parts/TabsHeader';
import { useUserState } from 'contexts/UserContext';
import { BarChartResolutions, Resolutions } from 'enums';
import { DateTime } from 'luxon';
import { IndexAggregation } from 'models/Index/IndexAggregation.model';
import { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getIndexAggregation } from 'repository';
import { useGet } from 'repository/useGet';
import { accumulateAggregationsBasedOnResolution, calculateAggregationsAverageValue } from 'utils/aggregationHelpers';
import { colors, fonts } from 'utils/constants';
import { formatDateTime, generateDateStringFromTwoDates } from 'utils/dateHelpers';
import { useStore } from 'utils/store';
import { gtm, IndexDetailDownloadButtonTagManagerArgs, pushTagManagerLayer } from 'utils/tagManagerArgs';
import BarChart from '../../parts/BarChart';
import Button from '../../parts/Button';
import ArrowSelector from './ArrowSelector';
import Tabs from './Tabs';

interface IndexDetailOverviewProps {
  indexId: string;
  indexName: string;
  greenThreshold: number;
  yellowThreshold: number;
}

const style = StyleSheet.create({
  base: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '1.4em',
    paddingRight: '1.4em',
  },
  averageContainer: {
    marginTop: '1em',
    marginLeft: '1.4em',
    minHeight: '76px',
  },
});

export default function IndexDetailOverview({ indexId, indexName, greenThreshold, yellowThreshold }: IndexDetailOverviewProps) {
  const today = DateTime.now();

  const [indexAggregation, setIndexAggregation] = useState<IndexAggregation[]>([]);
  const [selectedIndexDetailResolution, setSelectedIndexDetailResolution] = useState<Resolutions>(Resolutions.day);

  const [date, setDate] = useState(DateTime.now());

  const setIndexDetailResolution = (value: Resolutions) => {
    setSelectedIndexDetailResolution(value);
    setDate(DateTime.now());
  };

  const {
    reportPopup: { showReportPopup },
  } = useStore();

  const [isAverageVisible, setIsAverageVisible] = useState(true);

  const [dateRangeStart, setDateRangeStart] = useState(date.startOf(selectedIndexDetailResolution));
  const [dateRangeEnd, setDateRangeEnd] = useState(date);
  const [nextDateDisabled, setNextDateDisabled] = useState(true);

  const user = useUserState();

  const userId = user.id;
  const negateDateBy = selectedIndexDetailResolution === Resolutions.day ? 0 : 1;

  const { data: indexes } = useGet(
    ['getIndexAggregations', indexId, selectedIndexDetailResolution, formatDateTime(dateRangeStart)],
    ({ queryKey }) => getIndexAggregation(String(queryKey[1]), queryKey[2] as Resolutions, String(queryKey[3])),
    {
      enabled: !!indexId,
      onSuccess: (data) => {
        setIndexAggregation(data);
      }
    }
  );

  const indexData = accumulateAggregationsBasedOnResolution(indexAggregation || [], BarChartResolutions[selectedIndexDetailResolution], date);

  const setPreviousDate = () => {
    setDate(date.minus({ [selectedIndexDetailResolution]: 1 }));
  };

  const setNextDate = () => {
    if (date.endOf(selectedIndexDetailResolution) > DateTime.now()) {
      return;
    }

    setDate(date.plus({ [selectedIndexDetailResolution]: 1 }));
  };

  useEffect(() => {
    if (date.endOf(selectedIndexDetailResolution) > DateTime.now()) {
      setNextDateDisabled(true);
    } else {
      setNextDateDisabled(false);
    }
  }, [date, selectedIndexDetailResolution]);

  const downloadButtonDataLayer = IndexDetailDownloadButtonTagManagerArgs(indexName, indexId);

  useEffect(() => {
    setDateRangeStart(date.startOf(selectedIndexDetailResolution));
    setDateRangeEnd(date.endOf(selectedIndexDetailResolution) > DateTime.now() ? date : date.endOf(selectedIndexDetailResolution));
  }, [date, selectedIndexDetailResolution]);

  return (
    <View style={style.base}>
      <View style={style.headingContainer}>
        <Text style={fonts.headline4}>Index overview</Text>
        <Button
          justIcon={true}
          iconName={'downloadIconBlue'}
          iconWidth={32}
          iconHeight={32}
          onPress={() => {
            pushTagManagerLayer(downloadButtonDataLayer);
            showReportPopup();
          }}
        />
      </View>

      <TabsHeader
        tabs={[
          {
            label: Resolutions.day,
          },
          { label: Resolutions.week },
          { label: Resolutions.month },
          { label: Resolutions.year },
        ]}
        value={selectedIndexDetailResolution}
        onChange={(value: Resolutions) => {
          setIndexDetailResolution(value);
          gtm.push({
            event: 'navIndexOverview',
            stringValue: value,
            indexName: indexName,
            indexId: indexId,
          });
        }}
      />

      <View style={style.averageContainer}>
        {isAverageVisible ? (
          <>
            <Text style={{ ...fonts.paragraph, color: colors.grayScale.darkNeutral60 }}>Average</Text>
            <View style={{ marginTop: '4px' }}>
              <Text style={{ ...fonts.headline5, color: colors.grayScale.darkNeutral100 }}>
                {calculateAggregationsAverageValue(indexAggregation || [])}/10
              </Text>
            </View>
            <View style={{ marginTop: '4px' }}>
              <Text style={{ ...fonts.medium, color: colors.grayScale.darkNeutral80 }}>
                {generateDateStringFromTwoDates(dateRangeStart, dateRangeEnd)}
              </Text>
            </View>
          </>
        ) : null}
      </View>

      <BarChart
        dataset={indexData}
        datatype={selectedIndexDetailResolution}
        greenLineAtNumber={greenThreshold}
        orangeLineAtNumber={yellowThreshold}
        setIsAverageVisible={(value: boolean) => setIsAverageVisible(value)}
      />

      <ArrowSelector
        indexName={indexName}
        indexId={indexId}
        setPreviousDate={setPreviousDate}
        setNextDate={setNextDate}
        date={date}
        resolution={selectedIndexDetailResolution}
        nextIsDisabled={nextDateDisabled}
      />
    </View>
  );
}
