import { axisBottom, axisLeft, max, NumberValue, scaleBand, scaleLinear, select, selectAll } from 'd3';
import { Resolutions } from 'enums';
import { MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import { View, Dimensions, StyleSheet, TouchableOpacityComponent } from 'react-native';
import { colors, fonts } from 'utils/constants';
import { useWindowSize } from 'utils/useWindowSize';

interface BarChartInterface {
  dataset: {
    name: string;
    value: number;
  }[];
  datatype: Resolutions;
  greenLineAtNumber: number;
  orangeLineAtNumber: number;
  report?: boolean;
  setIsAverageVisible?: (value: boolean) => void;
}

export default function BarChart({ dataset, datatype, greenLineAtNumber, orangeLineAtNumber, setIsAverageVisible, report = false }: BarChartInterface) {
  const toolTip = useRef<HTMLElement>(null);

  const size = useWindowSize();

  const [screenWidth, setScreenWidth] = useState(report === true ? 526 : size.width - 40);

  useEffect(() => {
    if (report === false) {
      setScreenWidth(size.width - 40);
    }
  }, [size.width])

  const height = 300;

  const paddings = {
    [Resolutions.day]: 6,
    [Resolutions.week]: 18,
    [Resolutions.month]: 6,
    [Resolutions.year]: 10,
  }

  const labelCounts = {
    [Resolutions.day]: 4,
    [Resolutions.week]: 7,
    [Resolutions.month]: 4,
    [Resolutions.year]: 12,
  }

  const margin = { top: 100, right: 30, bottom: 0, left: 30 };

  const graphWidth = screenWidth - margin.right - margin.left;
  const graphHeight = 360 - margin.top - margin.bottom;

  useEffect(() => {
    dataset = dataset.map(dp => { dp.value = dp.value; return dp; })

    // const x = scaleBand()
    //   .domain(dataset.map(d => d.name))
    //   .range([0, graphWidth])
    //   .padding(paddings[datatype])

    const y = scaleLinear()
      .domain([0, 10])
      .range([graphHeight, 0])

    // Clear old graph
    select(`svg.${datatype} *`).remove();

    // Initiate the graph
    const svg = select(`svg.${datatype}`)
      .attr('width', screenWidth)
      .attr('height', height + 100)
      .append("g")
      .attr('transform', `translate(0, ${margin.top})`);

    // Append the xAxis (labels at the bottom)
    const xAxis = svg.append('g')
      .attr('transform', `translate(${paddings[datatype]/2}, ${graphHeight + 20})`);

    xAxis.selectAll('text')
        .data(dataset)
        .enter()
        .filter((d, i) => {
          const length = dataset.length === 31 ? 30 : dataset.length === 29 ? 28 : dataset.length;
          return i % Math.floor(length / labelCounts[datatype]) === 0;
        })
        .append('text')
          .attr('x', (d, i) => {
            const length = dataset.length === 31 ? 30 : dataset.length === 29 ? 28 : dataset.length;
            return ((i * Math.floor(dataset.length / labelCounts[datatype]) * graphWidth / dataset.length) + ((graphWidth/ dataset.length) - paddings[datatype])/2)
          })
          .text(d => { return d.name})
          .attr("text-anchor", "middle")
          .attr('font-size', `${fonts.small.fontSize}px`)
          .attr('font-family', `${fonts.small.fontFamily}`);

      xAxis.selectAll('path')
        .attr('stroke', 'none');


    // Append the yAxis (horizontal grid-lines)
    const yAxis = svg.append('g')
      .attr('transform', `translate(${graphWidth}, 0)`)
      .call(axisLeft(y).tickSize(graphWidth - 25));

      yAxis.selectAll('.tick line')
      .attr('stroke', colors.grayScale.darkNeutral100)
      .attr('opacity', '0.1')
      .attr('x2', -(graphWidth));

      yAxis.selectAll('.tick text')
        .attr('font-size', `${fonts.small.fontSize}px`)
        .attr('font-family', `${fonts.small.fontFamily}`)
        .attr('x', '20');

      yAxis.select(`:nth-child(${greenLineAtNumber + 2}) line`)
        .attr('stroke', colors.core.green)
        .attr('stroke-width', '2')
        .attr('opacity', 1);

      yAxis.select(`:nth-child(${orangeLineAtNumber + 2}) line`)
      .attr('stroke', colors.highlights.graphOrange)
      .attr('stroke-width', 2)
      .attr('opacity', 1);

      yAxis.selectAll('path')
        .attr('stroke', 'none');

    // Append the tooltip
    const tooltip = svg.append('g')
      .attr('class', 'tooltipGroup')
      .style('visibility', 'hidden')

    tooltip.append('line')
      .attr('x1', 106/2)
      .attr('x2', 106/2)
      .attr('y1', 8)
      .attr('y2', 350)
      .attr('stroke', colors.grayScale.lightNeutral100)

    tooltip.append('rect')
      .attr('width', 106)
      .attr('height', 79)
      .attr('fill', colors.core.cyan10)
      .attr('rx', '8')
      .attr('y', '8')

    tooltip.append('text')
      .text('Total')
      .attr('x', '15')
      .attr('y', 28)
      .attr('font-family', `${fonts.small.fontFamily}`)
      .attr('font-size', `0.8em`)
      .attr('font-weight', `${fonts.small.fontWeight}`)

    tooltip.append('text')
    .text('9/10')
      .attr('class', 'tooltipValue')
      .attr('x', '15')
      .attr('y', 56)
      .attr('font-family', `${fonts.bold.fontFamily}`)
      .attr('font-size', `1.3em`)
      .attr('font-weight', `${fonts.bold.fontWeight}`)

    tooltip.append('text')
      .text('7 may 2022')
      .attr('class', 'tooltipDate')
      .attr('x', '15')
      .attr('y', 78)
      .attr('font-family', `${fonts.small.fontFamily}`)
      .attr('font-size', `0.9em`)
      .attr('font-weight', `${fonts.small.fontWeight}`)

    // Append the bars
    svg.selectAll('.bar')
      .data(dataset)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('fill', (d, i) => d.value === 0 ? colors.grayScale.lightNeutral100 : colors.core.cyan)
      .attr('x', (d, i) => i * graphWidth / dataset.length + paddings[datatype]/2)
      .attr('width', (graphWidth/ dataset.length) - paddings[datatype])
      .attr('y', d => d.value === 0 ? 0 : y(d.value))
      .attr('height', d => d.value === 0 ? graphHeight : graphHeight - y(d.value))
      .attr('value', d => d.value)
      .on('mousedown', (e: any) => {
        const center = e.target.x.baseVal.value - (106/2) + (((graphWidth/ dataset.length) - paddings[datatype])/2);

        tooltip.select('.tooltipValue')
        .text(Math.floor(+select(e.target).attr('value')) + '/10')

        if (setIsAverageVisible) {
        //   setIsAverageVisible(false);
        }

        if (center < 0) {
          // tooltip.style('visibility', 'visible')
          //   .attr('transform', `translate(0, -100)`);

            tooltip.select('line')
              .attr('x1', e.target.width.baseVal.value / 2 + e.target.x.baseVal.value)
              .attr('x2', e.target.width.baseVal.value / 2 + e.target.x.baseVal.value)
        } else {
          // tooltip.style('visibility', 'visible')
          //   .attr('transform', `translate(${center}, -100)`);

            tooltip.select('line')
              .attr('x1', 106/2)
              .attr('x2', 106/2)
        }
      });

      select('body').on('mousedown', (e: any) => {
        const outside = !e.target.classList.contains('bar');

        if (outside) {
          tooltip.style('visibility', 'hidden');

          if (setIsAverageVisible) {
            // setIsAverageVisible(true);
          }
      }
      })
  }, [dataset, screenWidth]);



  return <svg className={datatype} style={{marginLeft: '1em', marginTop: '-80px'}}></svg>
}