import { ReactNode } from 'react';
import { ScrollView, View, Text, StyleSheet, StyleProp, ViewProps, StyleSheetProperties, ViewStyle } from 'react-native';
import { colors, fonts } from 'utils/constants';

interface RowProps {
    children?: ReactNode,
    style?: ViewStyle
}

export default function Row({ children, style, ...props }: RowProps) {
    const styles = StyleSheet.create({
        base: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            ...style
        },
    });

    return (
        <View style={styles.base}>
            {children}
        </View>
    )
}