export enum ApplyPolicy {
  AFTER_RESOLVER,
  BEFORE_RESOLVER,
}

export enum RefillAssortmentTypeEnum {
  SANITIZER = 0,
  SOAP = 1,
}

export enum BarChartResolutions {
  day = 'hours',
  week = 'daysOfWeek',
  month = 'days',
  year = 'months',
}

export enum Resolutions {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export enum DateFormats {
  hours = 'HH',
  oneDigitHour = 'H',
  days = 'dd',
  oneDigitDay = 'd',
  months = 'MM',
  monthInYear = 'MMMMM',
  oneDigitMonth = 'M',
  dayOfWeek = 'EEE',
  oneDigitDayOfWeek = 'E',
  day = 'd MMM',
  week = 'W',
  month = 'MMM',
  year = 'yyyy',
}

export enum ReportTypes {
  yesterday = 1,
  lastWeek = 2,
  lastMonth = 4,
  soFarThisYear = 8,
}

export enum Formats {
  landscape,
  portrait,
}

export enum OutputFormats {
  webUrl,
  pdf,
}

export enum Durations {
  TWENTY_FOUR_HOURS = 24,
  TWELVE_HOURS = 12,
  EIGHT_HOURS = 8,
}

export enum AppRoutes {
  SignIn = 'signin',
  SignOut = 'signout',
  Tutorial = 'tutorial',
  Setup = 'setup',
  Main = 'main',
  Rooms = 'rooms',
  Profile = 'profile',
  Help = 'help',
  SilentRenew = 'silent-renew',
}

export enum AppRouteParams {
  Locale = 'locale',
}
