import Button from 'components/parts/Button';
import Radio from 'components/parts/Radio';
import CustomTextInput from 'components/parts/TextInput';
import { useUserState } from 'contexts/UserContext';
import { Formats, OutputFormats } from 'enums';
import { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'utils/useNavigate';
import { updateIndexMessage } from 'repository';
import { usePost } from 'repository/usePost';
import { colors, fonts, globalStyles } from 'utils/constants';
import { useStore } from 'utils/store';
import {
  PublishModalCancelButtonTagManagerArgs,
  PublishModalCopyUrlAgainButtonTagManagerArgs,
  PublishModalCopyUrlButtonTagManagerArgs,
  PublishModalPreviewButtonTagManagerArgs,
  pushTagManagerLayer,
} from 'utils/tagManagerArgs';

const PublishIndexPopup = () => {
  const {
    publishPopup: { hidePublishPopup },
    indexId: { indexId },
    indexName: { indexName },
    week: { week },
  } = useStore();

  const style = StyleSheet.create({
    base: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 0px 32px rgba(40, 40, 40, 0.32)',
      height: '590px',
      width: '90%',
      left: '5%',
      top: '50%',
      transform: [{ translateY: -288 }],
      backgroundColor: colors.grayScale.lightNeutral0,
      borderRadius: 4,
      position: 'fixed',
      zIndex: 3,
      padding: '1em',
      paddingTop: '48px',
    },
    formContainer: {
      marginTop: '24px',
    },
    messageContainer: {
      marginTop: '32px',
    },
    radioContainer: {
      width: '80%',
      flexDirection: 'row',
    },
    buttonsContainer: {
      width: '80%',
      marginTop: '32px',
    },
    backdrop: {
      position: 'fixed',
      width: '100%',
      height: '100vh',
      top: 0,
      left: 0,
      backgroundColor: colors.grayScale.darkNeutral80,
      opacity: 0.5,
      zIndex: 2,
    },
    headingText: {
      ...fonts.headline5,
      color: colors.grayScale.darkNeutral100,
      paddingRight: '2em',
    },
    descriptiveText: {
      ...fonts.medium,
    },
    textInput: {
      ...globalStyles.textInput,
    },
    fieldInfoText: {
      ...fonts.small,
      color: colors.grayScale.darkNeutral80,
      marginTop: '1em',
    },
  });

  const [publishFormat, setPublishFormat] = useState<Formats>(Formats.landscape);
  const [outputFormat, setOutputFormat] = useState<OutputFormats>(OutputFormats.webUrl);
  const [copiedUrl, setCopiedUrl] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');

  const user = useUserState();

  const navigate = useNavigate();

  const userId = user.id;

  // const updateIndex = usePost<AxiosResponse, { id: string }>(
  //   (request: { id: string }) => putIndex(request.id, updateIndexData),
  //   () => {
  //     queryClient.invalidateQueries('getIndexById');
  //   }
  // );

  const updateMessage = usePost(() => updateIndexMessage(indexId, description));


  const handleUpdateMessage = () => {
    updateMessage.mutate({ id: indexId, description });
  };

  const copyUrl = () => {
    const url = `${window.location.origin}/#/public/${indexId}/${week}?landscape=${publishFormat}&pdf=0`;
    navigator.clipboard.writeText(url);

    setCopiedUrl(true);
  };

  const openNewWindow = () => {
    setTimeout(() => {
      hidePublishPopup();

      const url = `/public/${indexId}/${week}?landscape=${publishFormat}&pdf=1`;
      navigate(url, 'Index Public View');
    }, 500);
  };

  const copyUrlButtonDataLayer = PublishModalCopyUrlButtonTagManagerArgs(indexName, indexId!, publishFormat, description);
  const copyUrlAgainbuttonDataLayer = PublishModalCopyUrlAgainButtonTagManagerArgs(indexName, indexId!, publishFormat, description);
  const previewPdfButtonDataLayer = PublishModalPreviewButtonTagManagerArgs(indexName, indexId!, publishFormat, description);
  const cancelButtonDataLayer = PublishModalCancelButtonTagManagerArgs(indexName, indexId!);

  return (
    <>
      <View style={style.base}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={style.headingText}>Select which part you want to access</Text>
        </View>

        <View style={style.formContainer}>
          <Text style={style.descriptiveText}>Select format</Text>
          <View style={style.radioContainer}>
            <Radio
              text={'Landscape'}
              value={Formats.landscape}
              type="circle"
              style={{ marginRight: '24px' }}
              activeValue={publishFormat}
              setValue={setPublishFormat}
            />
            <Radio text={'Portrait'} value={Formats.portrait} type="circle" activeValue={publishFormat} setValue={setPublishFormat} />
          </View>
        </View>

        <View style={style.formContainer}>
          <Text style={style.descriptiveText}>Select output</Text>
          <View style={style.radioContainer}>
            <Radio
              text={'Web URL'}
              value={OutputFormats.webUrl}
              type="circle"
              style={{ marginRight: '24px' }}
              activeValue={outputFormat}
              setValue={setOutputFormat}
            />
            <Radio text={'PDF'} value={OutputFormats.pdf} type="circle" activeValue={outputFormat} setValue={setOutputFormat} />
          </View>
        </View>

        <View style={style.messageContainer}>
          <Text style={style.descriptiveText}>Message (optional)</Text>
          <CustomTextInput
            style={style.textInput}
            placeholder="Short message"
            maxLength={40}
            setValue={(value: string) => setDescription(value)}
            value={description}
          />
          <Text style={style.fieldInfoText}>Max 40 characters</Text>
        </View>

        <View style={style.buttonsContainer}>
          {outputFormat === OutputFormats.pdf ? (
            <Button
              onPress={() => {
                pushTagManagerLayer(previewPdfButtonDataLayer);
                handleUpdateMessage();
                openNewWindow();
              }}
              style={{ width: '198px' }}
              iconName={'eyeIconWhite'}
              iconWidth={24}
              iconHeight={24}
              buttonText={'Preview PDF'}
            />
          ) : copiedUrl ? (
            <Button
              onPress={() => {
                pushTagManagerLayer(copyUrlButtonDataLayer);
                handleUpdateMessage();
                copyUrl();
              }}
              style={{ width: '164px' }}
              iconName={'checkmarkIcon'}
              iconWidth={16}
              iconHeight={16}
              buttonText={'URL Copied'}
            />
          ) : (
            <Button
              onPress={() => {
                pushTagManagerLayer(copyUrlAgainbuttonDataLayer);
                copyUrl();
              }}
              style={{ width: '159px' }}
              iconName={'copyIcon'}
              iconWidth={24}
              iconHeight={24}
              buttonText={'Copy URL'}
            />
          )}

          <Button
            buttonText="Cancel"
            type="secondary"
            onPress={() => {
              pushTagManagerLayer(cancelButtonDataLayer);
              hidePublishPopup();
            }}
            style={{ marginTop: '1em' }}
          />
        </View>
      </View>

      <TouchableOpacity
        style={style.backdrop}
        onPress={() => {
          pushTagManagerLayer(cancelButtonDataLayer);
          hidePublishPopup();
        }}
      />
    </>
  );
};

export default PublishIndexPopup;
