import Column from 'components/parts/Column';
import ProgressBar from 'components/parts/ProgressBar';
import Row from 'components/parts/Row';
import { useUserState } from 'contexts/UserContext';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { useParams } from 'react-router-dom';
import { colors, fonts } from 'utils/constants';
import {
  DispenserStatusEmptyTagManagerArgs,
  DispenserStatusFullTagManagerArgs,
  IndexDetailPassagesTagManagerArgs,
  pushTagManagerLayer,
} from 'utils/tagManagerArgs';
import DispenserStatus from './DispenserStatus';

interface IndexDetailHeadProps {
  locationName: string;
  title: string;
  startDate: string;
  lastUpdate: string;
  dispenserIsEmpty: boolean;
  yesterdayVisitors: number;
  todayVisitors: number;
  yesterdayProgress: number;
  todayProgress?: number;
  donutSpanStart: string;
  donutSpanEnd: string;
  redDispenserCount: number;
  greenDispenserCount: number;
  greenThreshold: number;
  yellowThreshold: number;
}

const style = StyleSheet.create({
  base: {},
});

export default function IndexDetailHead({
  locationName,
  title,
  startDate,
  lastUpdate,
  yesterdayVisitors,
  todayVisitors,
  yesterdayProgress,
  todayProgress,
  redDispenserCount,
  greenDispenserCount,
  greenThreshold,
  yellowThreshold,
}: IndexDetailHeadProps) {
  const { index } = useParams();
  const user = useUserState();

  const userId = user.id;

  const dispenserFullDataLayer = DispenserStatusFullTagManagerArgs(title, index!);
  const dispenserEmptyDataLayer = DispenserStatusEmptyTagManagerArgs(title, index!);
  const passagesDataLayer = IndexDetailPassagesTagManagerArgs(todayVisitors, title, index!);

  return (
    <View style={style.base}>
      <Text style={{ ...fonts.small, color: colors.grayScale.darkNeutral80 }}>{locationName}</Text>
      <Text style={{ ...fonts.headline4, color: colors.grayScale.darkNeutral100 }}>{title}</Text>

      <Row style={{ marginTop: '1em' }}>
        {/* <Column>
          <Text style={{ ...fonts.paragraph, color: colors.grayScale.darkNeutral60 }}>Start date</Text>
          <Text style={{ ...fonts.bold, color: colors.grayScale.darkNeutral100 }}>{startDate}</Text>
        </Column> */}

        <Column>
          <Text style={{ ...fonts.paragraph, color: colors.grayScale.darkNeutral60 }}>Last update</Text>
          <Text style={{ ...fonts.bold, color: colors.grayScale.darkNeutral100 }}>{lastUpdate}</Text>
        </Column>
      </Row>

      <Row style={{ marginTop: '1em' }}>
        <Column style={{ height: 60 }}>
          <Text style={{ ...fonts.paragraph, color: colors.grayScale.darkNeutral60 }}>Dispenser status</Text>

          <Row>
            {greenDispenserCount ? (
              <Pressable
                style={{ width: '36px', marginTop: '5px', alignItems: 'center', marginLeft: '10px' }}
                onPress={() => {
                  pushTagManagerLayer(dispenserFullDataLayer);
                }}>
                <DispenserStatus empty={false} numberOf={greenDispenserCount} />
              </Pressable>
            ) : null}
            {redDispenserCount ? (
              <Pressable
                style={{ width: '36px', marginTop: '5px', alignItems: 'center' }}
                onPress={() => {
                  pushTagManagerLayer(dispenserEmptyDataLayer);
                }}>
                <DispenserStatus empty={true} numberOf={redDispenserCount} />
              </Pressable>
            ) : null}
          </Row>
        </Column>

        <Column style={{ height: 60 }}>
          <Pressable
            onPress={() => {
              pushTagManagerLayer(passagesDataLayer);
            }}>
            <Text style={{ ...fonts.paragraph, color: colors.grayScale.darkNeutral60 }}>Passages today</Text>
            <Text style={{ ...fonts.bold, color: colors.grayScale.darkNeutral80 }}>{todayVisitors}</Text>
          </Pressable>
        </Column>
      </Row>

      <Row style={{ marginTop: '2em', justifyContent: 'center' }}>
        <ProgressBar
          yesterdayProgress={yesterdayProgress}
          todayProgress={todayProgress}
          backgroundColor={colors.grayScale.lightNeutral20}
          greenThreshold={greenThreshold}
          yellowThreshold={yellowThreshold}
          passages={yesterdayVisitors}
        />
      </Row>
    </View>
  );
}
