import axios, { AxiosError } from 'axios';
import EditIndex from 'components/EditIndex';
import IndexDetail from 'components/IndexDetail';
import Menu from 'components/Menu';
import Note from 'components/Note/Note';
import Notes from 'components/Notes/Notes';
import Overlays from 'components/Overlays';
import Public from 'components/Public';
import Publish from 'components/Publish';
import Report from 'components/Report';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IndexStoreProvider } from 'stores/indexStore/indexStore';
import { ReactHooksWrapper } from 'utils/ReactHookOutside';
import StoreProvider from 'utils/store';
import './App.css';
import Dashboard from './components/Dashboard';
import { Route, Router, Routes } from './routing.web';

import Login from 'components/Login';
import ProtectedRoute from 'components/ProtectedRoute';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import CreateNote from 'components/CreateNote/CreateNote';
import EditNote from 'components/EditNote/EditNote';
import { jwtDecode } from 'utils/jwtDecode';
import { globalTagManagerArgs, pushTagManagerLayer } from 'utils/tagManagerArgs';

function App() {
  function getUserId() {
    const token = localStorage.getItem('token');
    
    if (token) {
      return jwtDecode(token).userId;
    }

    return null;
  }

  const tagManagerArgs = {
    gtmId: 'GTM-KSJ99SG',
    dataLayerName: 'dataLayer',
  };

  const userId = getUserId();

  if (userId) {
    pushTagManagerLayer(globalTagManagerArgs(userId));
  }

  TagManager.initialize(tagManagerArgs);

  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  axios.defaults.baseURL = 'https://hiqessitydev-func.azurewebsites.net/api'; // backend api url
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  useEffect(() => {
    console.log(axios.defaults.headers);
  }, []);

  // axios.defaults.headers.common = ''

  axios.interceptors.request.use(
    function (config: any) {
      // Do something before request is sent
      return config;
    },
    function (error: any) {
      // Do something with request error

      return Promise.reject(error);
    }
  );
  // Add a response interceptor
  axios.interceptors.response.use(
    function (response: any) {
      // Do something with response data
      return response;
    },
    function (error: AxiosError) {

      if (error.response?.status === 401) {
        window.location.href = '/#/login';
      }

      return Promise.reject(error);
    }
  );

  return (
    <QueryClientProvider client={queryClient}>
      <IndexStoreProvider>
        <ReactHooksWrapper />

        <StoreProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/detail/:indexId"
                element={
                  <ProtectedRoute>
                    <IndexDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/report/:indexId/:flag"
                element={
                  <ProtectedRoute>
                    <Report />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/editIndex/:indexId"
                element={
                  <ProtectedRoute>
                    <EditIndex />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notes"
                element={
                  <ProtectedRoute>
                    <Notes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notes/create"
                element={
                  <ProtectedRoute>
                    <CreateNote />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notes/:noteId/edit"
                element={
                  <ProtectedRoute>
                    <EditNote />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notes/:noteId"
                element={
                  <ProtectedRoute>
                    <Note />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/publish"
                element={
                  <ProtectedRoute>
                    <Publish />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/public/:indexId/:week"
                element={
                  <ProtectedRoute>
                    <Public />
                  </ProtectedRoute>
                }
              />
            </Routes>

            <Menu />
            <Overlays />
          </Router>
        </StoreProvider>
      </IndexStoreProvider>
    </QueryClientProvider>
  );
}

export default App;
