import Card from 'components/parts/Card';
import Image from 'components/parts/Image';
import TabsHeader from 'components/parts/TabsHeader';
import { Resolutions } from 'enums';
import { DateTime } from 'luxon';
import { Index } from 'models/Index/Index.model';
import { IndexAggregation } from 'models/Index/IndexAggregation.model';
import { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { getAllIndexAggregations, getIndexes } from 'repository';
import { useGet } from 'repository/useGet';
import { colors, fonts } from 'utils/constants';
import { formatDateTime, generateDateStringFromTwoDates, getDateRange } from 'utils/dateHelpers';
import { useStore } from 'utils/store';
import { PublishPageSwitchWeekTabTagManagerArgs, pushTagManagerLayer } from 'utils/tagManagerArgs';
import IndexIconCircle from '../parts/IndexIconCircle';
import SmallIndexCircle from '../parts/SmallIndexCircle';
import WeeklyReport, { Report } from './WeeklyReport';

interface PublishProps {}

const Publish: React.FC<PublishProps> = () => {
  const {
    publishPopup: { showPublishPopup },
  } = useStore();

  const style = StyleSheet.create({
    base: {
      paddingBottom: '100px',
    },
    wrapper: {
      paddingTop: '2em',
      width: '100%',
    },
    blueBackground: {
      width: '100%',
      height: '22.5%',
      backgroundColor: colors.core.cyan10,
      position: 'absolute',
      top: 0,
      zIndex: 0,
    },
    headWrapper: {
      padding: '2em',
    },
    cardContent: {
      padding: '1em',
      paddingBottom: 0,
      paddingTop: '32px',
      alignItems: 'center',
    },
    downloadButton: {
      marginTop: '16px',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopWidth: 1,
      height: '65px',
      borderTopColor: colors.grayScale.lightNeutral60,
    },
    downloadButtonText: {
      ...fonts.bold,
      color: colors.core.blue,
      marginLeft: '1em',
    },
  });

  const today = DateTime.local();
  const [aggregations, setAggregations] = useState<Map<string, IndexAggregation[]>>(new Map());
  const [indexIds, setIndexIds] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('Last Week');
  const [weekView, setWeekView] = useState<number>(0);
  const [startDate, setStartDate] = useState<DateTime>(today.minus({ weeks: weekView }).startOf('week'));
  const [endDate, setEndDate] = useState<DateTime>(today.minus({ weeks: weekView }).endOf('week'));

  // #region FETCH DATA

  const { data: indexes } = useGet(['getIndexes'], () => getIndexes(), {
    enabled: true,
    onSuccess: (data) => setIndexIds(data.map((i) => i.guid)),
  });

  useGet(['getAllIndexAggregations', startDate], () => getAllIndexAggregations(indexIds, Resolutions.week, formatDateTime(startDate)), {
    enabled: !!indexIds.length,
    onSuccess: (data) => {
      let t = data.flat();

      const groupedMap: Map<string, IndexAggregation[]> = t.reduce(
        (entryMap, e) => entryMap.set(e.indexId, [...(entryMap.get(e.indexId) || []), e]),
        new Map()
      );

      setAggregations(groupedMap);
    },
  });

  // #endregion FETCH DATA

  useEffect(() => {
    switch (selectedTab) {
      case 'Last Week':
        setWeekView(1);
        break;
      case 'Current Week':
        setWeekView(0);
        break;
      default:
        setWeekView(0);
        break;
    }
  }, [selectedTab]);

  useEffect(() => {
    setStartDate(today.minus({ weeks: weekView }).startOf('week'));
    setEndDate(today.minus({ weeks: weekView }).endOf('week'));
  }, [weekView]);

  const generateReport = (aggregations: IndexAggregation[], index: Index) => {
    let icon = '';
    let days = getDateRange(startDate, endDate);

    let ds = days.map((day) => {
      // i'm so sorry this is so ugly
      let aggregation = aggregations.find((a) => DateTime.fromISO(a.aggregationStartAt).toFormat('yyyy-MM-dd') === day);

      if (aggregation) {
        if (today.toISO() < aggregation.aggregationStartAt) icon = 'circleGrey';
        else if (aggregation.progress >= index.greenThreshold) icon = 'circleGreen';
        else if (aggregation.progress <= index.yellowThreshold) icon = 'circleRed';
        else if (aggregation.progress > index.yellowThreshold && aggregation.progress < index.greenThreshold) icon = 'circleOrange';

        return {
          date: DateTime.fromISO(aggregation.aggregationStartAt).toFormat('yyyy-MM-dd'),
          icon: icon,
        };
      } else {
        return {
          date: day,
          icon: 'circleGrey',
        };
      }
    });

    return ds;
  };

  // get an average from a list of days and return a corresponding color. ie green, yellow, red, or grey
  const getMainIconColor = (report: Report[]) => {
    let avg: string[] = [];

    report.forEach((day) => avg.push(day.icon));
    
    let avgColor = avg.sort((a, b) => avg.filter((v) => v === a).length - avg.filter((v) => v === b).length).pop();

    return avgColor?.substring(6) || 'Grey';
  };

  return (
    <ScrollView style={style.base}>
      <View style={style.wrapper}>
        <View style={style.blueBackground}></View>
        <View style={style.headWrapper}>
          <Text style={{ ...fonts.headline1, color: colors.grayScale.darkNeutral100 }}>Publish views</Text>
          <TabsHeader
            value={selectedTab}
            onChange={(value) => { setSelectedTab(value); pushTagManagerLayer(PublishPageSwitchWeekTabTagManagerArgs(value));  }}
            tabs={[
              {
                label: 'Last Week',
              },
              {
                label: 'Current Week',
              },
            ]}
          />
        </View>
        {indexes?.map((index, i) => {
          let aggs = aggregations.get(index.guid);

          let report = generateReport(aggs || [], index) || [];

          return (
            <Card
              style={{ marginTop: i === 0 ? 0 : '1em', alignItems: 'center', padding: 0 }}
              key={`publix_index_card_${index.guid}_key${i}`}>
              <View style={style.cardContent}>
                <Text style={{ ...fonts.headline4, color: colors.grayScale.darkNeutral100 }}>{index.indexName}</Text>

                <IndexIconCircle
                  color={getMainIconColor(report)}
                  indexId={index.guid}
                  greenThreshold={index.greenThreshold}
                  yellowThreshold={index.yellowThreshold}
                  indexType={index.dispenserType}
                  marginTop="24px"
                />

                <SmallIndexCircle
                  indexId={index.guid}
                  greenThreshold={index.greenThreshold}
                  yellowThreshold={index.yellowThreshold}
                  dateRange={generateDateStringFromTwoDates(startDate, endDate)}
                />

                <WeeklyReport report={report} />
              </View>

              <TouchableOpacity style={style.downloadButton} onPress={() => showPublishPopup(index.guid, weekView)}>
                <Image imageName="downloadIconBlue" style={{ width: '24px', height: '24px' }} />
                <Text style={style.downloadButtonText}>Download View</Text>
              </TouchableOpacity>
            </Card>
          );
        })}
      </View>
    </ScrollView>
  );
};

export default Publish;
