import Card from 'components/parts/Card';
import Circle from 'components/parts/Circle';
import Image from 'components/parts/Image';
import ProgressBar from 'components/parts/ProgressBar';
import Row from 'components/parts/Row';
import { useUserState } from 'contexts/UserContext';
import { observer } from 'mobx-react-lite';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'utils/useNavigate';
import { getIndexes } from 'repository';
import { useGet } from 'repository/useGet';
import { colors, fonts } from 'utils/constants';
import { useStore } from 'utils/store';
import { pushTagManagerLayer, QuickActionTagManagerArgs, ViewDetailsTagManagerArgs } from 'utils/tagManagerArgs';

const style = StyleSheet.create({
  base: {},
  wrapper: {
    width: '100%',
    paddingTop: '2em',
    marginBottom: '100px',
  },
  blueBackground: {
    width: '100%',
    height: '275px',
    backgroundColor: colors.core.cyan10,
    position: 'absolute',
    top: 0,
    zIndex: 0,
  },
  headWrapper: {
    padding: '2em',
  },
  detailCircle: {
    color: colors.core.cyan,
    border: `1px solid ${colors.core.cyan}`,
    marginLeft: '10px',
    borderRadius: 100,
    width: '12px',
    height: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardSiteNameText: {
    ...fonts.small,
  },
});

type DashboardProps = {};

const Dashboard: React.FC<DashboardProps> = observer(() => {
  const {
    indexSettingsMenu: { showIndexSettingsMenu },
    indexId: { setIndexId },
    indexName: { setIndexName },
  } = useStore();

  const { data: indexes } = useGet(['getIndexes'], () => getIndexes(), {
    onSuccess: (data) => console.log(data),
  });

  const navigate = useNavigate();
  const user = useUserState();

  const userId = user.id;

  console.log('Re-rendering Dashboard');

  return (
    <ScrollView style={style.base}>
      <View style={style.wrapper}>
        <View style={style.blueBackground}></View>
        <View style={style.headWrapper}>
          <Text style={{ ...fonts.headline1, color: colors.grayScale.darkNeutral100 }}>Hello!</Text>
          <Text style={{ ...fonts.preamble, color: colors.grayScale.darkNeutral100 }}>Here is today's current status</Text>
        </View>

        {indexes &&
          indexes.map((index, i) => {

            const moreOptionsDataLayer = QuickActionTagManagerArgs(index.indexName, index.guid); // used to be location alias

            const viewDetailsDataLayer = ViewDetailsTagManagerArgs(index.indexName, index.guid); // used to be location alias

            return (
              <Card style={i !== 0 ? { marginTop: '1em' } : {}} key={`bogus_dashboard_key_for_card_${i}`}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Text>{index.locationName}</Text>

                  <TouchableOpacity
                    onPress={() => {
                      setIndexId(index.guid);
                      setIndexName(index.indexName || '');
                      pushTagManagerLayer(moreOptionsDataLayer);
                      showIndexSettingsMenu(index.guid);
                    }}>
                    <Image imageName="moreIcon" style={{ width: '32px', height: '8px' }} />
                  </TouchableOpacity>
                </Row>

                <Text style={{ ...fonts.headline4, color: colors.grayScale.darkNeutral100, marginTop: '4px' }}>{index.indexName}</Text>

                <TouchableOpacity
                  onPress={() => {
                    navigate(`/detail/${index.guid}`, 'Index Details');
                    pushTagManagerLayer(viewDetailsDataLayer);
                  }}>
                  <Row style={{ marginTop: '8px' }}>
                    <Circle size={16} color={index.dispensers?.empty > 0 ? colors.highlights.red : colors.highlights.darkGreen} />
                    <Text style={{ ...fonts.bold, color: colors.core.blue, marginLeft: '0.4em' }}>View details</Text>
                    <Image imageName="nextIconCircleBlue" style={{ marginLeft: '8px', width: '16px', height: '16px' }} />
                  </Row>
                </TouchableOpacity>

                <Row style={{ marginTop: '24px', justifyContent: 'center' }}>
                  <ProgressBar
                    yesterdayProgress={index.yesterdayProgress}
                    todayProgress={index.todayProgress}
                    backgroundColor={colors.grayScale.lightNeutral0}
                    greenThreshold={index.greenThreshold}
                    yellowThreshold={index.yellowThreshold}
                    passages={index.yesterdayVisitors}
                  />
                </Row>
              </Card>
            );
          })}
      </View>
    </ScrollView>
  );
});

export default Dashboard;
