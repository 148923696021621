import Image from "components/parts/Image";
import { View, StyleSheet, TouchableOpacity, ViewStyle, Text } from "react-native";
import { colors, fonts } from "utils/constants";

interface ButtonProps {
  style?: ViewStyle,
  justIcon?: boolean,
  disabled?: boolean,
  iconName?: string,
  iconWidth?: number,
  iconHeight?: number,
  buttonText?: string,
  onPress: () => void,
  type?: 'primary' | 'secondary'
}

export default function Button({ style, justIcon, disabled, iconName, iconWidth, iconHeight, buttonText, onPress, type = 'primary' }: ButtonProps) {
  const styles = StyleSheet.create({
    primaryBase: {
      backgroundColor: colors.core.blue,
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '24px',
      paddingRight: '24px',
      borderRadius: 99,
      height: '48px',
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      ...style,
    },
    primaryText: {
      ...fonts.bold,
      marginLeft: !iconName ? 0 : '8px',
      color: colors.grayScale.lightNeutral0,
    },
    disabledPrimaryBase: {
      backgroundColor: colors.grayScale.lightNeutral60,
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '24px',
      paddingRight: '24px',
      borderRadius: 99,
      height: '48px',
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      ...style,
    },
    disabledPrimaryText: {
      ...fonts.bold,
      marginLeft: !iconName ? 0 : '8px',
      color: colors.grayScale.darkNeutral10,
    },
    secondaryBase: {
      borderColor: colors.core.blue,
      borderWidth: 2,
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '24px',
      paddingRight: '24px',
      borderRadius: 99,
      height: '48px',
      width: '95px',
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      ...style,
    },
    secondaryText: {
      ...fonts.bold,
      color: colors.core.blue,
      marginLeft: !iconName ? 0 : '8px',
    },
    disabledSecondaryBase: {
      borderColor: colors.grayScale.lightNeutral60,
      borderWidth: 2,
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '24px',
      paddingRight: '24px',
      borderRadius: 99,
      height: '48px',
      width: '95px',
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      ...style,
    },
    disabledSecondaryText: {
      ...fonts.bold,
      color: colors.grayScale.lightNeutral60,
      marginLeft: !iconName ? 0 : '8px',
    },
    justIcon: {
      width: iconWidth,
      height: iconHeight,
      marginTop: '4px',
    }
  });

  let buttonStyle, buttonTextStyle;

  if (type === 'primary') {
    if (!disabled) {
      buttonStyle = styles.primaryBase;
      buttonTextStyle = styles.primaryText;
    } else {
      buttonStyle = styles.disabledPrimaryBase;
      buttonTextStyle = styles.disabledPrimaryText;
    }
  } else if (type === 'secondary') {
    if (!disabled) {
      buttonStyle = styles.secondaryBase;
      buttonTextStyle = styles.secondaryText;
    } else {
      buttonStyle = styles.disabledSecondaryBase;
      buttonTextStyle = styles.disabledSecondaryText;
    }
  }

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      {!justIcon ? (
        <View style={buttonStyle}>
          {iconName ? <Image imageName={iconName} style={{ width: `${iconWidth}px`, height: `${iconHeight}px`}} /> : null}
          <Text style={buttonTextStyle}>
            {buttonText}
          </Text>
        </View>
      ) : (
        <Image imageName={iconName!} style={styles.justIcon} />
      )}
    </TouchableOpacity>
  )
}