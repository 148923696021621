import axios, { AxiosResponse } from 'axios';
import { Resolutions } from 'enums';
import { DateTime } from 'luxon';
import { Index } from 'models/Index/Index.model';
import { IndexAggregation } from 'models/Index/IndexAggregation.model';
import { UpdateIndex } from 'models/Index/UpdateIndex.model';

// GET
// /essity/v1/indexes
export const getIndexes = async (): Promise<Index[]> => {
  const localTimeStartOfDay = DateTime.local().startOf('day');
  const utcTime = localTimeStartOfDay.toUTC();

  console.log(utcTime);

  const res = await axios.get(`/indexes/${utcTime.toISO()}`);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
};

// /essity/v1/index/{id}
export const getIndex = async (id: string): Promise<Index> => {
  const localTimeStartOfDay = DateTime.local().startOf('day');
  const utcTime = localTimeStartOfDay.toUTC();

  console.log(utcTime);
  
  const res = await axios.get(`/indexes/${id}/${utcTime.toISO()}`);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
};

export const getIndexAggregation = async (id: string, interval: Resolutions, startDate?: string): Promise<IndexAggregation[]> => {
  const luxonStartDate: DateTime = DateTime.fromISO(startDate || '');
  const utcTime = luxonStartDate.toUTC();

  const res = await axios.get(`/indexes/${id}/${interval.charAt(0).toUpperCase() + interval.slice(1)}/${utcTime.toISO()}`);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
};

// this is scuffed but idk how to do it. we should prob have an endpoint for getting a collection of aggregations
export const getAllIndexAggregations = async (
  ids: string[],
  interval: Resolutions,
  startDate?: string
): Promise<IndexAggregation[][]> => {
  let promises: Promise<any>[] = [];
  let intvl = interval.charAt(0).toUpperCase() + interval.slice(1);

  const luxonStartDate: DateTime = DateTime.fromISO(startDate || '');
  const utcTime = luxonStartDate.toUTC();

  ids.forEach((id) => {
    const res = axios.get<IndexAggregation[]>(`/indexes/${id}/${intvl}/${utcTime.toISO()}`);

    promises.push(res);
  });

  let aggregations = await Promise.all(promises).then((values) => {
    
    values.forEach((v) => {
      if (v.status === 401) {
        window.location.href = '/#/login';
      }
    });

    return values;
  });

  return aggregations.map((a) => a.data);
};

// #region PUT

export const putIndex = async (indexId: string, request: UpdateIndex | undefined): Promise<AxiosResponse> => {
  if (!!request) {
    const res = await axios.put(`/indexes/${indexId}`, request);

    if (res.status === 401) {
      window.location.href = '/#/login';
    }

    return res;
  } else {
    const res = await axios.put(`/indexes/${indexId}`);

    if (res.status === 401) {
      window.location.href = '/#/login';
    }

    return res;
  }
};

export const updateIndexMessage = async (indexId: string, message: string): Promise<AxiosResponse> => {
  const res = await axios.put(`/indexes/${indexId}/message`, { message });

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res;
};

// #endregion PUT

// #region DELETE

export const deleteIndex = async (indexId: string): Promise<AxiosResponse> => {
  const res = await axios.delete(`/indexes/delete/${indexId}`);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res;
};

// #endregion DELETE
