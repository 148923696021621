import { To, useNavigate as useReactRouterNavigate } from 'react-router-dom';
import { ChangePageViewTagManagerArgs, pushTagManagerLayer } from './tagManagerArgs';

export const useNavigate = () => {
    const navigate = useReactRouterNavigate();

    return (url: To, pageTitle: string) => {
        console.log(typeof(url));
        if (typeof(url) === 'string') {
            pushTagManagerLayer(ChangePageViewTagManagerArgs(url, pageTitle));
        }

        navigate(url);
    };
};
