import Circle from 'components/parts/Circle';
import Column from 'components/parts/Column';
import Image from 'components/parts/Image';
import Row from 'components/parts/Row';
import { View, StyleSheet, ViewStyle, Text, ColorPropType } from 'react-native';
import { colors, fonts } from 'utils/constants';

interface DispenserStatusProps {
    style?: ViewStyle;
    empty?: boolean;
    numberOf: number;
}

export default function DispenserStatus({ style, empty, numberOf }: DispenserStatusProps) {
    const styles = StyleSheet.create({
        base: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            borderRadius: 100,
            border: `1px solid ${colors.core.cyan}`,
            width: 35,
            height: 35,
            ...style
        },
    });

    return (
        <Column style={{ alignItems: 'center', width: 0, height: 0, flexBasis: 36, flex: 0 }}>
            <View style={styles.base}>
                <Image imageName='dispenser' style={{ width: 20, height: 20, resizeMode: 'contain' }} />
                <Circle size={16} color={!empty ? colors.highlights.darkGreen : colors.highlights.red} style={{ position: 'absolute', right: -3, top: -3 }} />
                <View style={{ position: 'absolute', top: -3, right: 1.5}}>
                    <Text style={{...fonts.smallBold, color: colors.grayScale.lightNeutral0}}>{numberOf}</Text>
                </View>
            </View>
            <Text style={{ ...fonts.smallBold, marginTop: '5px' }}>{empty ? 'Empty' : 'Full'}</Text>
        </Column>
    )
}