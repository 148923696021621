import { BarChartResolutions, DateFormats } from 'enums';
import { DateTime, Info } from 'luxon';
import { IndexAggregation } from 'models/Index/IndexAggregation.model';

export function calculateAggregationsAverageValue(aggregations: IndexAggregation[]) {
  if (aggregations.length === 0) {
    return 0;
  }
  
  const totalDispenses = aggregations?.reduce((acc, cur) => {
    return acc + cur.dispenses;
  }, 0);

  const totalVisitors = aggregations?.reduce((acc, cur) => {
    return acc + cur.visitors;
  }, 0);

  const dispenserTypeMultiplier = aggregations[0].dispenserType === 0 ? 1 : 1.5;

  let averageValue = totalDispenses / totalVisitors / dispenserTypeMultiplier;
  
  if (averageValue > 10) {
    averageValue = 10;
  }

  return Math.floor(averageValue * 10);
}

export function calculateTotalPassages(aggregations: IndexAggregation[]) {
  // console.log("Calculating total passages");

  return (
    aggregations?.reduce((acc, cur) => {
      // console.log(acc, cur);
      return acc + cur.visitors;
    }, 0) || 0
  );
}

export function calculateTotalDispenses(aggregations: IndexAggregation[]) {
  return (
    aggregations?.reduce((acc, cur) => {
      return acc + cur.dispenses;
    }, 0) || 0
  );
}

interface ExtendedBarChartData {
  name: string;
  nameNumber: number;
  value: number;
}

export interface BarChartData {
  name: string;
  value: number;
}

export function accumulateAggregationsBasedOnResolution(
  aggregations: IndexAggregation[],
  resolution: BarChartResolutions,
  date: DateTime
): BarChartData[] {
  if (resolution === BarChartResolutions.day) {
    return accumulateAggregationsBasedOnDay(aggregations);
  } else if (resolution === BarChartResolutions.week) {
    return accumulateAggregationsBasedOnDaysOfWeek(aggregations);
  } else if (resolution === BarChartResolutions.month) {
    return accumulateAggregationsBasedOnDaysInMonth(aggregations, date.minus({ month: 1 }));
  } else if (resolution === BarChartResolutions.year) {
    return accumulateAggregationsBasedOnMonthsInYear(aggregations);
  }

  return [];
}

function accumulateAggregationsBasedOnDay(aggregations: IndexAggregation[]): BarChartData[] {
  const extendedData: ExtendedBarChartData[] = Array.from({ length: 24 }).map((_, i) => ({
    name: i.toString(),
    nameNumber: i,
    value: 0,
    count: 0,
  }));
  const data: BarChartData[] = [];

  aggregations.map((aggregation, i) => {
    const offset = DateTime.now().offset / 60;
    let name = Number(DateTime.fromJSDate(new Date(aggregation.aggregationStartAt)).toFormat(DateFormats['oneDigitHour'])) + offset;
    
    if (name === 24) {
      name = 0;
    }

    const value = aggregation?.progress || 0;

    extendedData[name].value = value;
  });

  extendedData.reduce((acc, cur) => {
    data.push({ name: cur.name, value: cur.value });

    return acc;
  }, data);

  return data;
}

function accumulateAggregationsBasedOnDaysOfWeek(aggregations: IndexAggregation[]) {
  const extendedData: ExtendedBarChartData[] = Array.from({ length: 7 }).map((_, i) => ({
    name: Info.weekdays('short', { locale: 'en-GB' })[i],
    nameNumber: i,
    value: 0,
  }));
  const data: BarChartData[] = [];

  let mondayAggregationStartAt = aggregations.reduce((acc, cur) => {
    const name = DateTime.fromJSDate(new Date(cur.aggregationStartAt)).toFormat(DateFormats['dayOfWeek'], { locale: 'en-GB' });

    if (name === 'Mon') {
      return cur.aggregationStartAt;
    }

    return acc;
  }, '');

  aggregations.map((aggregation) => {
    const name = DateTime.fromJSDate(new Date(aggregation.aggregationStartAt)).toFormat(DateFormats['dayOfWeek'], { locale: 'en-GB' });
    let value = aggregation?.progress || 0;

    // If mondayAggregationStartAt is greater than aggregationStartAt

    if (name !== 'Mon' && DateTime.fromJSDate(new Date(mondayAggregationStartAt)) > DateTime.fromJSDate(new Date(aggregation.aggregationStartAt))) {
      value = 0;
    }

    const d = extendedData.find((d) => d.name === name);

    if (d) {
      d.value = value;
    }
  });

  extendedData.reduce((acc, cur) => {
    cur.value = cur.value === 0 ? 0 : Math.round((cur.value) * 10) / 10;
    data.push({ name: cur.name, value: cur.value });

    console.log(cur);

    return acc;
  }, data);

  console.log(data);

  return data;
}

function accumulateAggregationsBasedOnDaysInMonth(aggregations: IndexAggregation[], date: DateTime): BarChartData[] {
  const extendedData: ExtendedBarChartData[] = Array.from({ length: date.daysInMonth }).map((_, i) => ({
    name: (i + 1).toString(),
    nameNumber: i + 1,
    value: 0,
    count: 0,
  }));
  const data: BarChartData[] = [];

  aggregations.map((aggregation) => {
    const name = DateTime.fromJSDate(new Date(aggregation.aggregationStartAt)).toFormat(DateFormats['oneDigitDay']);
    const value = aggregation?.progress || 0;

    const d = extendedData.find((d) => d.name === name);

    if (d) {
      d.value = value;
    }
  });

  extendedData.reduce((acc, cur) => {
    cur.value = cur.value === 0 ? 0 : Math.round((cur.value) * 10) / 10;
    data.push({ name: cur.name, value: cur.value });

    return acc;
  }, data);

  return data;
}

function accumulateAggregationsBasedOnMonthsInYear(aggregations: IndexAggregation[]) {
  const extendedData: ExtendedBarChartData[] = Array.from({ length: 12 }).map((_, i) => ({
    name: Info.months('short')[i],
    nameNumber: i,
    value: 0,
    count: 0,
  }));
  const data: BarChartData[] = [];

  aggregations.map((aggregation) => {
    const name = DateTime.fromJSDate(new Date(aggregation.aggregationStartAt)).toFormat(DateFormats['month'], );
    const value = aggregation?.progress || 0;

    const d = extendedData.find((d) => d.name === name);

    if (d) {
      d.value += value;
    }
  });

  extendedData.reduce((acc, cur) => {
    cur.value = cur.value === 0 ? 0 : Math.round((cur.value) * 10) / 10;
    cur.name = Info.months('narrow', { locale: 'en-GB'})[cur.nameNumber];

    acc.push({ name: cur.name, value: cur.value });

    return acc;
  }, data);

  return data;
}
