import donutImageEmpty from './empty.png';
import donutImageGreen1 from './green-1.png';
import donutImageGreen2 from './green-2.png';
import donutImageGreen3 from './green-3.png';
import donutImageGreen4 from './green-4.png';
import donutImageGreen5 from './green-5.png';
import donutImageGreen6 from './green-6.png';
import donutImageGreen7 from './green-7.png';
import donutImageGreen8 from './green-8.png';
import donutImageGreen9 from './green-9.png';
import donutImageGreen10 from './green-10.png';
import donutImageRed1 from './red-1.png';
import donutImageRed2 from './red-2.png';
import donutImageRed3 from './red-3.png';
import donutImageRed4 from './red-4.png';
import donutImageRed5 from './red-5.png';
import donutImageRed6 from './red-6.png';
import donutImageRed7 from './red-7.png';
import donutImageRed8 from './red-8.png';
import donutImageRed9 from './red-9.png';
import donutImageRed10 from './red-10.png';
import donutImageToday0 from './today-0.png';
import donutImageToday1 from './today-1.png';
import donutImageToday2 from './today-2.png';
import donutImageToday3 from './today-3.png';
import donutImageToday4 from './today-4.png';
import donutImageToday5 from './today-5.png';
import donutImageToday6 from './today-6.png';
import donutImageToday7 from './today-7.png';
import donutImageToday8 from './today-8.png';
import donutImageToday9 from './today-9.png';
import donutImageToday10 from './today-10.png';
import donutImageTodayEmpty from './today-empty.png';
import donutImageYellow1 from './yellow-1.png';
import donutImageYellow2 from './yellow-2.png';
import donutImageYellow3 from './yellow-3.png';
import donutImageYellow4 from './yellow-4.png';
import donutImageYellow5 from './yellow-5.png';
import donutImageYellow6 from './yellow-6.png';
import donutImageYellow7 from './yellow-7.png';
import donutImageYellow8 from './yellow-8.png';
import donutImageYellow9 from './yellow-9.png';
import donutImageYellow10 from './yellow-10.png';

const Donuts = {
  donutImageEmpty,
  donutImageGreen1,
  donutImageGreen2,
  donutImageGreen3,
  donutImageGreen4,
  donutImageGreen5,
  donutImageGreen6,
  donutImageGreen7,
  donutImageGreen8,
  donutImageGreen9,
  donutImageGreen10,
  donutImageRed1,
  donutImageRed2,
  donutImageRed3,
  donutImageRed4,
  donutImageRed5,
  donutImageRed6,
  donutImageRed7,
  donutImageRed8,
  donutImageRed9,
  donutImageRed10,
  donutImageToday0,
  donutImageToday1,
  donutImageToday2,
  donutImageToday3,
  donutImageToday4,
  donutImageToday5,
  donutImageToday6,
  donutImageToday7,
  donutImageToday8,
  donutImageToday9,
  donutImageToday10,
  donutImageTodayEmpty,
  donutImageYellow1,
  donutImageYellow2,
  donutImageYellow3,
  donutImageYellow4,
  donutImageYellow5,
  donutImageYellow6,
  donutImageYellow7,
  donutImageYellow8,
  donutImageYellow9,
  donutImageYellow10,
};

export default Donuts;
