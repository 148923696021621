import axios from 'axios';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { loadState } from 'utils/storage';

interface ProtectedRouteProps {
  children: ReactElement;
  noRedirect?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, noRedirect }) => {
  const token = loadState({ key: 'token', storage: 'local' });

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return children;
  }

  return <Navigate to={'/login'} replace />;
};

export default ProtectedRoute;
