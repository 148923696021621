import { AxiosResponse } from 'axios';
import Button from 'components/parts/Button';
import Radio from 'components/parts/Radio';
import Row from 'components/parts/Row';
import Slider from 'components/parts/Slider';
import CustomTextInput from 'components/parts/TextInput';
import { useUserState } from 'contexts/UserContext';
import { Durations, RefillAssortmentTypeEnum } from 'enums';
import { DateTime } from 'luxon';
import { UpdateIndex } from 'models/Index/UpdateIndex.model';
import { useEffect, useState } from 'react';
import { NativeSyntheticEvent, ScrollView, StyleSheet, Text, TextInputChangeEventData, View } from 'react-native';
import { useQueryClient } from 'react-query';
import { useParams, useNavigate as useReactRouterDomNavigate } from 'react-router-dom';
import { useNavigate } from 'utils/useNavigate';
import { getIndex, putIndex } from 'repository';
import { useGet } from 'repository/useGet';
import { usePost } from 'repository/usePost';
import { colors, fonts, globalStyles } from 'utils/constants';
import { ValidateHourInput, ValidateMinuteInput } from 'utils/formValidators';
import { EditIndexSettingsCancelTagManagerArgs, EditIndexSettingsTagManagerArgs, pushTagManagerLayer } from 'utils/tagManagerArgs';

const EditIndex = () => {
  const style = StyleSheet.create({
    base: {
      padding: '2.8em',
      width: '100vw',
    },
    heading: {
      ...fonts.headline1,
    },
    paragraphText: {
      ...fonts.paragraph,
      color: colors.grayScale.darkNeutral100,
      whiteSpace: 'wrap',
      marginTop: '1em',
    },
    label: {
      ...fonts.medium,
      marginTop: '24px',
      color: colors.grayScale.darkNeutral100,
    },
    labelDetail: {
      ...fonts.small,
      marginTop: '1em',
      color: colors.grayScale.darkNeutral80,
    },
    textInput: {
      ...globalStyles.textInput,
    },
    greenThresholdsContainer: {},
    orangeThresholdsContainer: {
      marginTop: '8px',
      marginBottom: '27px',
    },
  });

  const queryClient = useQueryClient();

  const [updateIndexData, setUpdateIndexData] = useState<UpdateIndex>();

  const [yellowThreshold, setYellowThreshold] = useState(1);
  const [greenThreshold, setGreenThreshold] = useState(1);
  const [indexName, setIndexName] = useState<string>('');
  const [indexNameIsOkay, setIndexNameIsOkay] = useState(false);
  const [thresholdsAreOkay, setThresholdsAreOkay] = useState(true);

  const [indexType, setIndexType] = useState<RefillAssortmentTypeEnum>(RefillAssortmentTypeEnum.SOAP);

  const navigate = useNavigate();
  const reactRouterDomNavigate = useReactRouterDomNavigate();
  const { indexId } = useParams();
  const user = useUserState();

  const userId = user.id;

  // #region FETCH DATA
  const { data: index } = useGet(['getIndexById'], () => getIndex(indexId || '00000000-0000-0000-0000-000000000000'), {
    enabled: !!indexId,
    onSuccess: (data) => {
      setUpdateIndexData({
        indexName: data.indexName,
        greenThreshold: data.greenThreshold,
        yellowThreshold: data.yellowThreshold,
      });

      setYellowThreshold(data.yellowThreshold || 1);
      setGreenThreshold(data.greenThreshold || 1);
      setIndexName(data.indexName);
      setIndexType(data.dispenserType);
    },
  });

  const updateIndex = usePost<AxiosResponse, { id: string }>(
    (request: { id: string }) => putIndex(request.id, updateIndexData),
    () => {
      queryClient.invalidateQueries('getIndexById');
      navigate(`/detail/${indexId}`, 'Index Details');
    }
  );

  useEffect(() => {
    if (indexName.trim().length > 0) {
      setIndexNameIsOkay(true);
    } else {
      setIndexNameIsOkay(false);
    }

    if (yellowThreshold >= greenThreshold) {
      setThresholdsAreOkay(false);
    } else {
      setThresholdsAreOkay(true);
    }

    setUpdateIndexData({
      indexName: indexName,
      greenThreshold: greenThreshold,
      yellowThreshold: yellowThreshold,
    });
  }, [indexName, greenThreshold, yellowThreshold]);

  useEffect(() => {
    console.log('updateIndexData', updateIndexData);
  }, [updateIndexData]);

  const editIndexSettingsButtonDataLayer = EditIndexSettingsTagManagerArgs(
    yellowThreshold,
    greenThreshold,
    indexName,
    indexId!
  );
  const editIndexSettingsCancelButtonDataLayer = EditIndexSettingsCancelTagManagerArgs(indexName, indexId!);

  return (
    <ScrollView style={style.base}>
      <Text style={style.heading}>Index settings</Text>

      <View>
        <Text style={style.label}>Choose a name for this index</Text>
        <CustomTextInput
          style={style.textInput}
          value={indexName}
          setValue={(value: string) => {
            setIndexName(value);
          }}
          error={!indexNameIsOkay}
        />
      </View>

      <Text style={style.label}>What kind of index is this?</Text>
      <Row>
        <Radio
          text={'Soap'}
          type="circle"
          fillColor={colors.grayScale.darkNeutral60}
          value={1}
          disabled={true}
          activeValue={indexType}
          setValue={() => {}}
        />
        <Radio
          text={'Hand Sanitizer'}
          type="circle"
          disabled={true}
          fillColor={colors.grayScale.darkNeutral60}
          value={0}
          activeValue={indexType}
          setValue={() => {}}
          style={{ marginLeft: '24px' }}
        />
      </Row>

      <View style={style.greenThresholdsContainer}>
        <Text style={style.label}>Green threshold: {greenThreshold}</Text>
        <Slider style={{ marginTop: '1.5em' }} threshold={greenThreshold} setThreshold={setGreenThreshold} error={!thresholdsAreOkay} />
      </View>

      <View style={style.orangeThresholdsContainer}>
        <Text style={style.label}>Orange threshold: {yellowThreshold}</Text>
        <Slider style={{ marginTop: '1.5em' }} threshold={yellowThreshold} setThreshold={setYellowThreshold} error={!thresholdsAreOkay} />
      </View>

      <View style={{ marginTop: '32px'}} />

      <Button
        buttonText="Update index"
        disabled={!indexNameIsOkay || !thresholdsAreOkay}
        onPress={() => {
          pushTagManagerLayer(editIndexSettingsButtonDataLayer);
          if (indexId) updateIndex.mutate({ id: indexId });
        }}
        style={{ width: '156px' }}
      />

      <View style={{ marginTop: '1em' }} />

      <Button
        buttonText="Cancel"
        type="secondary"
        onPress={() => {
          pushTagManagerLayer(editIndexSettingsCancelButtonDataLayer);
          reactRouterDomNavigate(-1);
        }}
      />
    </ScrollView>
  );
};

export default EditIndex;
