import axios, { AxiosResponse } from 'axios';
import Note from 'interfaces/Note';
import { NotesData } from 'mock/NotesData';

// GET
// /essity/v1/notes
export const getNotes = async (): Promise<Note[]> => {
  const res = await axios.get<Note[]>(`/notes`);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
};

// /essity/v1/notes
export const getNote = async (noteId: string): Promise<Note> => {
  const res = await axios.get(`/notes/${noteId}`);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
};

type PostNoteInput = {
  guid?: string;
  title: string;
  body: string;
  siteId: number;
  private: boolean;
};

export const postNote = async (note: PostNoteInput): Promise<Note> => {
  const res = await axios.post(`/notes`, note);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
}

export const putNote = async (note: PostNoteInput): Promise<Note> => {
  const res = await axios.put(`/notes`, note);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
}

export const deleteNote = async (noteId: string): Promise<Note> => {
  const res = await axios.delete(`/notes/${noteId}`);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
}

export const toggleNotePinned = async (guid: string, pinned: boolean): Promise<Note> => {
  const res = await axios.patch(`/notes/${guid}/setpinned/${!pinned}`);

  if (res.status === 401) {
    window.location.href = '/#/login';
  }

  return res.data;
}