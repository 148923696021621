import { StyleProp, TextStyle } from 'react-native';

export const imageExtension = 'png';

export const colors = {
  core: {
    blue: '#003366',
    cyan: '#0099FF',
    cyan120: '#007ACC',
    cyan40: '#9BD7FD',
    cyan20: '#CDEBFE',
    cyan10: '#E7F4FF',
    green: '#66CC33',
    green20: '#E1F4D7',
    green10: '#E8F8E1',
  },
  grayScale: {
    lightNeutral0: '#FFFFFF',
    lightNeutral20: '#F8FAFC',
    lightNeutral60: '#E8EAEC',
    lightNeutral100: '#D8DADC',
    lightNeutral30: '#787878',
    darkNeutral10: '#BCBCBC',
    darkNeutral60: '#626262',
    darkNeutral80: '#3F3F3F',
    darkNeutral100: '#282828',
  },
  highlights: {
    purple: '#990099',
    teal: '#0079AD',
    orange: '#FF6600',
    darkGreen: '#007D32',
    red: '#CC0935',
    graphOrange: '#FFAF19',
    darkOrange: '#FA6400',
  },
};

interface fontInterface {
  paragraph: TextStyle;
  paragraphRoboto: TextStyle;
  bold: TextStyle;
  small: TextStyle;
  smallBoldHeader: TextStyle;
  smallBold: TextStyle;
  button: TextStyle;
  medium: TextStyle;
  preamble: TextStyle;
  headline5: TextStyle;
  headline4: TextStyle;
  headline4Roboto: TextStyle;
  headline3: TextStyle;
  headline2: TextStyle;
  headline1: TextStyle;
  largeText: TextStyle;
}
export const fonts: fontInterface = {
  paragraph: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
  },

  paragraphRoboto: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 20,
  },

  bold: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 20,
  },
  small: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 15,
  },
  button: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 15,
  },
  smallBoldHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 15,
  },
  smallBold: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 12,
    lineHeight: 15,
  },
  medium: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 19,
  },
  preamble: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 20,
    lineHeight: 24,
  },
  headline5: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 20,
    lineHeight: 24,
  },
  headline4: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 29,
  },
  headline4Roboto: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 29,
  },
  headline3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 28,
    lineHeight: 34,
  },
  headline2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 32,
    lineHeight: 39,
  },
  headline1: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 36,
    lineHeight: 44,
  },
  largeText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 48,
    lineHeight: 59,
  },
};

export const globalStyles = {
  textInput: {
    borderColor: colors.core.blue,
    borderWidth: 2,
    borderRadius: 3,
    height: '48px',
    marginTop: '0.5em',
    paddingLeft: '1em',
    paddingRight: '1em',
    outlineStyle: 'none',
    ...fonts.paragraph,
  },
};
