import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { colors, fonts } from 'utils/constants';
import Image from './Image';

interface RadioProps {
  text: string;
  value: number | string | boolean;
  type: 'square' | 'circle';
  activeValue: any;
  setValue: (value: any) => void;
  style?: ViewStyle;
  disabled?: boolean;
  fillColor?: string;
}

export default function Radio({ text, value, fillColor, type, activeValue, setValue, style, disabled }: RadioProps) {
  const styles = StyleSheet.create({
    base: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '1em',
      ...style,
    },
    squareButton: {
      borderRadius: 3,
      width: '24px',
      height: '24px',
      borderColor: colors.core.blue,
      borderWidth: 2,
      marginRight: '5px',
    },
    activeSquareButton: {
      borderRadius: 3,
      width: '24px',
      height: '24px',
      backgroundColor: colors.core.cyan,
      marginRight: '5px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    circleButton: {
      borderRadius: 24,
      width: '24px',
      height: '24px',
      borderColor: colors.core.blue,
      borderWidth: 2,
      marginRight: '5px',
    },
    activeCircleButton: {
      borderRadius: 24,
      width: '24px',
      height: '24px',
      backgroundColor: !!fillColor ? fillColor : colors.core.cyan,
      marginRight: '5px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeCircleMark: {
      width: '8px',
      height: '8px',
      borderRadius: 8,
      backgroundColor: colors.grayScale.lightNeutral0,
    },
    disabledCircleButton: {
      borderRadius: 24,
      width: '24px',
      height: '24px',
      borderColor: colors.grayScale.lightNeutral60,
      borderWidth: 2,
      marginRight: '5px',
    },
    disabledCircleMark: {
      width: '8px',
      height: '8px',
      borderRadius: 8,
      backgroundColor: 'transparent',
    },
    radioLabel: {
      ...fonts.paragraph,
      color: disabled ? colors.grayScale.darkNeutral10 : colors.grayScale.darkNeutral100,
    },
  });

  return (
    <TouchableOpacity style={styles.base} onPress={disabled ? () => {} : () => setValue(value)}>
      {type === 'square' ? (
        <>
          {activeValue.indexOf(value) > -1 ? (
            <View style={styles.activeSquareButton}>
              <Image imageName="checkmarkIcon" style={{ width: '14px', height: '10px' }} />
            </View>
          ) : (
            <View style={styles.squareButton}></View>
          )}
        </>
      ) : (
        <View style={activeValue === value ? styles.activeCircleButton : disabled ? styles.disabledCircleButton : styles.circleButton}>
          {activeValue === value ? <View style={styles.activeCircleMark}></View> : null}
        </View>
      )}
      <Text style={styles.radioLabel}>{text}</Text>
    </TouchableOpacity>
  );
}
