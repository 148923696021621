import { useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Image from 'components/parts/Image';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'utils/useNavigate';
import { getNote } from 'repository/notes';
import { useGet } from 'repository/useGet';
import { colors, fonts } from 'utils/constants';
import { dayMonthYear } from 'utils/dateHelpers';
import { useStore } from 'utils/store';
import { Site } from 'models/Index/Site.model';
import { getUserSites } from 'repository/account';

type Props = {};

const style = StyleSheet.create({
  base: {},
  wrapper: {
    width: '100%',
    paddingTop: '3em',
    marginBottom: '100px',
    padding: '2em',
  },
  topWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '2em',
    paddingBottom: '0',
  },
});

type Params = {
  noteId?: string;
};

const Note: React.FC<Props> = () => {
  const [userSites, setUserSites] = useState<Site[]>([]);
  
  useGet(['getUserSites'], () => getUserSites(), {
    onSuccess: (data) => {
      console.log('yay my sites:', data);
      
      if (data.length > 1) {
        data = [...data];
      }

      setUserSites(data);
    },
    onError: (error) => console.log(error),
  });

  const {
    noteSettingsMenu: { showNoteSettingsMenu },
    noteTitle: { setNoteTitle },
    noteStatus: { setNoteStatus },
    noteAssociatedSite: { setNoteAssociatedSite },
  } = useStore();
  
  const navigate = useNavigate();

  const { noteId } = useParams<Params>();

  const { data: note } = useGet(['getNote', noteId], () => getNote(noteId || '0'));

  return note ? (
    <ScrollView style={style.base}>
      <View style={style.topWrapper}>
        <TouchableOpacity
          onPress={() => {
            navigate('/notes', 'Notes');
          }}>
          <Image imageName="backIcon" style={{ width: '8.66px', height: '16px' }} />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => { showNoteSettingsMenu(); setNoteTitle(note.title); setNoteStatus(note.private ? "Private" : "Public"); setNoteAssociatedSite(userSites.find((site) => site.siteId === note.siteId)?.siteName || "None"); }}>
          <Image imageName="moreIcon" style={{ width: '32px', height: '8px' }} />
        </TouchableOpacity>
      </View>

      <View style={style.wrapper}>
        <View>
          <Text style={{ ...fonts.small, color: colors.grayScale.darkNeutral80 }}>Created by {note.createdBy}</Text>
          <Text style={{ ...fonts.headline4Roboto, color: colors.grayScale.darkNeutral100, padding: '4px 0' }}>{note.title}</Text>
          <Text style={{ ...fonts.small, color: colors.grayScale.lightNeutral30 }}>Created {dayMonthYear(note.createdAt)}</Text>
          <Text style={{ ...fonts.paragraphRoboto, color: colors.grayScale.darkNeutral100, paddingTop: '1em' }}>{note.body}</Text>
        </View>
      </View>
    </ScrollView>
  ) : (
    <View>
      <Text>No note found</Text>
    </View>
  );
};

export default Note;
