import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'utils/useNavigate';
import { colors, fonts } from 'utils/constants';
import { CreateNewNoteTagManagerArgs, pushTagManagerLayer } from 'utils/tagManagerArgs';

const CreateNewNoteButton = () => {
  const style = StyleSheet.create({
    base: {
      position: 'absolute',
      flexDirection: 'row',
      alignItems: 'center',
      bottom: 90,
      right: 20,
      backgroundColor: colors.core.blue,
      paddingTop: '14px',
      paddingBottom: '14px',
      paddingLeft: '16px',
      paddingRight: '16px',
      borderRadius: 32,
    },
    plusContainer: {
      width: '12px',
      height: '12px',
      marginLeft: '8px',
    },
    plusLineHorizontal: {
      position: 'absolute',
      width: '12px',
      height: '1px',
      backgroundColor: colors.grayScale.lightNeutral0,
      left: -6,
      top: 6,
    },
    plusLineVertical: {
      position: 'absolute',
      width: '1px',
      height: '12px',
      backgroundColor: colors.grayScale.lightNeutral0,
      left: 0,
      top: 0,
    },
  });

  const navigate = useNavigate();

  const createNewNoteButtonDataLayer = CreateNewNoteTagManagerArgs();

  return (
    <TouchableOpacity
      style={style.base}
      onPress={() => {
        pushTagManagerLayer(createNewNoteButtonDataLayer);
        navigate('/notes/create', 'Create Note');
      }}>
      <View style={style.plusContainer}>
        <View style={style.plusLineHorizontal}></View>
        <View style={style.plusLineVertical}></View>
      </View>
      <Text style={{ ...fonts.button, color: colors.grayScale.lightNeutral0 }}>New note</Text>
    </TouchableOpacity>
  );
};

export default CreateNewNoteButton;
