import { useUserState } from 'contexts/UserContext';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'utils/useNavigate';
import { deleteNote } from 'repository/notes';
import { usePost } from 'repository/usePost';
import { colors, fonts } from 'utils/constants';
import { useStore } from 'utils/store';
import {
  DeleteNoteConfirmationCancelButtonTagManagerArgs,
  DeleteNoteConfirmationTagManagerArgs,
  pushTagManagerLayer,
} from 'utils/tagManagerArgs';
import Button from '../parts/Button';

export default function ConfirmDeleteNoteDialog() {
  const {
    confirmDeleteNoteDialog: { hideConfirmDeleteNoteDialog },
    noteSettingsMenu: { hideNoteSettingsMenu },
    noteId: { noteId },
  } = useStore();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const style = StyleSheet.create({
    base: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: '0px 0px 32px rgba(40, 40, 40, 0.32)',
      height: '212px',
      width: '90%',
      left: '5%',
      top: '50%',
      transform: [{ translateY: -217 }],
      backgroundColor: colors.grayScale.lightNeutral0,
      borderRadius: 4,
      position: 'fixed',
      zIndex: 3,
    },
    radioContainer: {
      width: '80%',
      marginTop: '8px',
    },
    buttonsContainer: {
      paddingLeft: '32px',
      paddingRight: '32px',
      width: '100%',
      marginTop: '24px',
      flexDirection: 'row',
    },
    backdrop: {
      position: 'fixed',
      width: '100%',
      height: '100vh',
      top: 0,
      left: 0,
      backgroundColor: colors.grayScale.darkNeutral80,
      opacity: 0.5,
      zIndex: 2,
    },
  });

  const user = useUserState();

  const userId = user.id;

  const removeNote = usePost(
    () => deleteNote(noteId || ""),
    () => {
      
      queryClient.invalidateQueries('getNotes')
      console.log(`Deleted Note ${noteId} successfully`);
      hideConfirmDeleteNoteDialog();
    },
    () => {
      console.log(`Something went wrong deleting Note ${noteId}`);
    }
  );

  const deleteNoteConfirmationButtonDataLayer = DeleteNoteConfirmationTagManagerArgs();
  const deleteNoteCancelButtonDataLayer = DeleteNoteConfirmationCancelButtonTagManagerArgs();

  return (
    <>
      <View style={style.base}>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={{
              ...fonts.headline5,
              color: colors.grayScale.darkNeutral100,
              marginTop: '48px',
              paddingLeft: '32px',
              paddingRight: '32px',
            }}>
            Are you sure you want to delete this note?
          </Text>
        </View>

        <View style={style.buttonsContainer}>
          <Button
            onPress={() => {
              pushTagManagerLayer(deleteNoteConfirmationButtonDataLayer);
              hideConfirmDeleteNoteDialog();
              navigate('/notes', 'Notes');
              removeNote.mutate({ id: noteId });
            }}
            style={{ width: '107px' }}
            buttonText={'Delete'}
          />

          <Button
            buttonText="Cancel"
            type="secondary"
            onPress={() => {
              pushTagManagerLayer(deleteNoteCancelButtonDataLayer);
              hideConfirmDeleteNoteDialog();
            }}
            style={{ marginLeft: '16px' }}
          />
        </View>
      </View>
      <TouchableOpacity
        style={style.backdrop}
        onPress={() => {
          pushTagManagerLayer(deleteNoteCancelButtonDataLayer);
          hideConfirmDeleteNoteDialog();
        }}
      />
    </>
  );
}
