import { Text, StyleSheet, TouchableOpacity, View } from 'react-native';
import { colors, fonts } from 'utils/constants';
import { pushTagManagerLayer } from 'utils/tagManagerArgs';
import Tab from './Tab';

type TabProps = {
  label: string;
  icon?: React.ReactNode;
};

type TabsHeaderProps = {
  tabs: TabProps[];
  onChange: (value: any) => void;
  value: string;
};

type StyleProps = {
  value: string;
  label: string;
};

const style = (props?: StyleProps) => {
  return StyleSheet.create({
    base: {
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '4px',
      paddingBottom: '4px',
      flexDirection: 'row',
      backgroundColor: colors.core.cyan20,
      borderRadius: 3,
      width: '90%',
      alignSelf: 'center',
      marginTop: '1em',
    },
    tab: {
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 3,
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '6px',
      paddingBottom: '6px',
      flex: 1,
      backgroundColor: props?.value.includes(props.label) ? colors.grayScale.lightNeutral0 : 'transparent',
    },
  });
};
const TabsHeader = ({ tabs, value, onChange }: TabsHeaderProps) => {
  return (
    <View style={style().base}>
      {tabs.map((tab, i) => (
        <TouchableOpacity
          key={`tab_${i}`}
          style={style({ value: value, label: tab.label }).tab}
          onPress={() => {
            if (onChange) onChange(tab.label);
          }}>
          <Text style={{ ...fonts.bold, color: value.includes(tab.label) ? colors.core.blue : 'inherit' }}>{tab.label[0].toUpperCase() + tab.label.slice(1)}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default TabsHeader;
