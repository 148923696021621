import Image from 'components/parts/Image';
import { RefillAssortmentTypeEnum, Resolutions } from 'enums';
import { DateTime } from 'luxon';
import { StyleSheet, View } from 'react-native';
import { getIndexAggregation } from 'repository';
import { useGet } from 'repository/useGet';
import { calculateAggregationsAverageValue } from 'utils/aggregationHelpers';
import { colors } from 'utils/constants';

interface IndexIconCircleProps {
  indexId: string;
  color?: string;
  indexType: RefillAssortmentTypeEnum;
  greenThreshold: number;
  yellowThreshold: number;
  marginTop?: string;
  large?: boolean;
}

const IndexIconCircle = ({ color, indexId, indexType, greenThreshold, yellowThreshold, marginTop, large }: IndexIconCircleProps) => {
  // console.log('large', large);
  const styles = StyleSheet.create({
    iconCircle: {
      color: colors.core.cyan,
      borderRadius: large ? 250 : 185,
      width: large ? '250px' : '185px',
      height: large ? '250px' : '185px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.grayScale.lightNeutral0,
      marginTop: marginTop || '0px',
    },
  });

  const circleStyles = StyleSheet.create({
    greenIconCircle: {
      backgroundColor: colors.core.green,
    },
    redIconCircle: {
      backgroundColor: colors.highlights.red,
    },
    orangeIconCircle: {
      backgroundColor: colors.highlights.darkOrange,
    },
    grayIconCircle: {
      backgroundColor: colors.grayScale.lightNeutral100,
    },
  });

  const greenIconCircle = StyleSheet.compose(styles.iconCircle, circleStyles.greenIconCircle);
  const redIconCircle = StyleSheet.compose(styles.iconCircle, circleStyles.redIconCircle);
  const orangeIconCircle = StyleSheet.compose(styles.iconCircle, circleStyles.orangeIconCircle);
  const grayIconCircle = StyleSheet.compose(styles.iconCircle, circleStyles.grayIconCircle);

  const today = DateTime.local();
  const todayString = today.toString();
  const timeZoneOffset = todayString.toString().substring(todayString.length - 6, todayString.length);

  const startOfLastWeek = today.minus({ weeks: 1 }).startOf('week');
  const endOfLastWeek = today.minus({ weeks: 1 }).endOf('week');

  const average = calculateAggregationsAverageValue([]) * 10;

  const circleColor =
    color === 'Grey' ? grayIconCircle : color === 'Green' ? greenIconCircle : color === 'Orange' ? orangeIconCircle : redIconCircle;

  // const circleColor =
  //   average === 0
  //     ? grayIconCircle
  //     : average >= greenThreshold
  //     ? greenIconCircle
  //     : average >= yellowThreshold
  //     ? orangeIconCircle
  //     : redIconCircle;

  return (
    <View style={circleColor}>
      <Image
        imageName={'handSoapIcon'}
        style={{ width: large ? '170px' : '125px', height: large ? '170px' : '125px', marginTop: -20 }}
      />
    </View>
  );
};

export default IndexIconCircle;
