import { iconMappings } from 'iconMappings';
import { CSSProperties } from 'react';
import { Image as ReactImage, ImageStyle, Text } from 'react-native';
import { imageExtension} from 'utils/constants';

interface ImageProps {
    imageName: string;
    style?: ImageStyle;
    htmlStyle?: CSSProperties;
    htmlImg?: boolean;
}

export default function Image({ imageName, style, htmlImg, htmlStyle, ...props }: ImageProps) {
    // console.log("Image: " + imageName);
    if (iconMappings[imageName] === undefined) {
        return <Text>{imageName}</Text>;
    }

    if (htmlImg === true) {
        return <img src={iconMappings[imageName][imageExtension].source} style={{...htmlStyle, objectFit: 'contain', backgroundSize: 'contain' }} {...props} />;
    }

    return <ReactImage source={iconMappings[imageName][imageExtension].source} style={style} {...props} />
}