import Row from 'components/parts/Row';
import { Resolutions } from 'enums';
import { DateTime } from 'luxon';
import { StyleSheet, Text } from 'react-native';
import { getIndexAggregation } from 'repository';
import { useGet } from 'repository/useGet';
import { calculateAggregationsAverageValue } from 'utils/aggregationHelpers';
import { colors, fonts } from 'utils/constants';
import { generateDateStringFromTwoDates } from 'utils/dateHelpers';

interface SmallIndexCircleProps {
  indexId: string;
  greenThreshold: number;
  yellowThreshold: number;
  dateRange?: string;
}

const SmallIndexCircle = ({ indexId, greenThreshold, yellowThreshold, dateRange }: SmallIndexCircleProps) => {
  const style = StyleSheet.create({
    dayOfMonthText: {
      ...fonts.headline1,
      marginLeft: '10px',
    },
  });

  const today = DateTime.local();
  const todayString = today.toString();
  const timeZoneOffset = todayString.toString().substring(todayString.length - 6, todayString.length);

  const startOfLastWeek = today.minus({ weeks: 1 }).startOf('week');
  const endOfLastWeek = today.minus({ weeks: 1 }).endOf('week');

  const average = calculateAggregationsAverageValue([]) * 10;

  const smallCircleColor =
    average === 0
      ? colors.grayScale.lightNeutral100
      : average >= greenThreshold
      ? colors.core.green
      : average >= yellowThreshold
      ? colors.highlights.darkOrange
      : colors.highlights.red;
  const smallCircleIcon =
    average === 0 ? '' : average >= greenThreshold ? 'checkmarkIcon' : average >= yellowThreshold ? 'alertIcon' : 'crossIcon';

  return (
    <Row style={{ justifyContent: 'center', marginTop: '16px' }}>
      {/* <Circle size={26} color={smallCircleColor}>
        {smallCircleIcon !== '' ? <Image imageName={smallCircleIcon} style={{ width: '16px', height: '16px' }} /> : null}
      </Circle> */}
      <Text style={style.dayOfMonthText}>
        {dateRange ? dateRange : generateDateStringFromTwoDates(startOfLastWeek, endOfLastWeek, 'dd')}
      </Text>
    </Row>
  );
};

export default SmallIndexCircle;
