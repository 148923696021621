import { useLocation, useParams } from 'react-router-dom';
import { useStore } from 'utils/store';
import ConfirmDeleteIndexDialog from './ConfirmDeleteIndexDialog';
import ConfirmSignoutDialog from './ConfirmSignoutDialog';
import IndexMenu from './IndexMenu';
import PublishIndexPopup from './PublishIndexPopup';
import ReportPopup from './ReportPopup';
import CreateNewNoteButton from './CreateNewNoteButton';
import NoteMenu from './NoteMenu';
import ConfirmDeleteNoteDialog from './ConfirmDeleteNoteDialog';

export default function Overlays() {
  const {
    reportPopup: { isReportPopupVisible },
    publishPopup: { isPublishPopupVisible },
    indexSettingsMenu: { isIndexSettingsMenuVisible },
    noteSettingsMenu: { isNoteSettingsMenuVisible },
    confirmSignoutDialog: { isConfirmSignoutDialogVisible },
    confirmDeleteIndexDialog: { isConfirmDeleteIndexDialogVisible },
    confirmDeleteNoteDialog: { isConfirmDeleteNoteDialogVisible },
    indexId: { indexId },
  } = useStore();

  return (
    <>
      {isReportPopupVisible ? <ReportPopup indexId={indexId} /> : null}
      {isPublishPopupVisible ? <PublishIndexPopup /> : null}
      {isIndexSettingsMenuVisible ? <IndexMenu /> : null}
      {isNoteSettingsMenuVisible ? <NoteMenu /> : null}
      {isConfirmSignoutDialogVisible ? <ConfirmSignoutDialog /> : null}
      {isConfirmDeleteIndexDialogVisible ? <ConfirmDeleteIndexDialog /> : null}
      {isConfirmDeleteNoteDialogVisible ? <ConfirmDeleteNoteDialog /> : null}
      {useLocation().pathname === '/notes' ? <CreateNewNoteButton /> : null}
    </>
  );
}
