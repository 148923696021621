import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import { colors, fonts } from 'utils/constants';
import Circle from './Circle';
import Image from './Image';
interface ProgressBarProps {
  yesterdayProgress: number;
  todayProgress?: number;
  greenThreshold: number;
  yellowThreshold: number;
  backgroundColor?: string;
  passages: number;
  report?: boolean;
  style?: ViewStyle;
}

export default function ProgressBar({
  yesterdayProgress = 0,
  todayProgress = 0,
  report = false,
  greenThreshold,
  yellowThreshold,
  backgroundColor,
  passages,
  style,
}: ProgressBarProps) {
  const color = yesterdayProgress >= greenThreshold ? 'green' : yesterdayProgress >= yellowThreshold ? 'yellow' : 'red';

  const styles = StyleSheet.create({
    base: {
      width: '240px',
      height: '240px',
      position: 'relative',
      backgroundColor: backgroundColor,
      ...style,
    },
    amountTextContainer: {
      position: 'absolute',
      margin: 'auto',
      marginTop: '30px',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '200px',
      height: '200px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 4,
    },
    reportAmountTextContainer: {
      position: 'absolute',
      margin: 'auto',
      marginTop: '15px',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '200px',
      height: '200px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 4,
    },
    averageText: {
      ...fonts.preamble,
      color: colors.grayScale.darkNeutral80,
      marginTop: '5px',
      fontSize: 16,
    },
    smallText: {
      ...fonts.preamble,
      color: colors.grayScale.darkNeutral80,
      fontSize: 12,
      marginTop: '5px',
    },
  });

  yesterdayProgress = yesterdayProgress > 10 ? 10 : yesterdayProgress;
  todayProgress = todayProgress > 10 ? 10 : todayProgress;

  const imageName = yesterdayProgress <= 0 ? 'donut-empty' : `donut-${color}-${yesterdayProgress}`;
  const todayImage = todayProgress <= 0 ? 'donut-today-empty' : `donut-today-${todayProgress}`;

  return (
    <View style={styles.base}>
      <Image imageName={imageName} style={{ width: '240px', height: '205px', resizeMode: 'contain' }} />
      <Image
        imageName={todayImage}
        style={{ width: '200px', height: '171px', resizeMode: 'contain', position: 'absolute', top: '35px', left: '20px' }}
      />

      <View style={!report ? styles.amountTextContainer : styles.reportAmountTextContainer }>
        {!report ? <Text style={styles.averageText}>Yesterday</Text> : null}
        <Text style={fonts.largeText}>{yesterdayProgress}/10</Text>
        <Text style={styles.averageText}>{passages} Passages</Text>
        {!report ? <Text style={styles.smallText}>Today so far</Text> : null}
        {!report ? <Text style={{ ...styles.smallText, marginTop: '0' }}>
          <Circle size={10} color={colors.core.cyan} style={{ marginRight: '5px' }} />
          {todayProgress}/10
        </Text> : null }
      </View>
    </View>
  );
}
