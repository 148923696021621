import Button from "components/parts/Button";
import { useUserState } from "contexts/UserContext";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-dom";
import { colors, fonts } from "utils/constants";
import { useStore } from "utils/store";
import { CancelIndexMenuTagManagerArgs, DeleteIndexTagManagerArgs, IndexSettingsTagManagerArgs, pushTagManagerLayer } from "utils/tagManagerArgs";

const IndexMenu = () => {
  const style = StyleSheet.create({
    base: {
      position: "absolute",
      bottom: 0,
      height: '210px',
      width: '100%',
      backgroundColor: colors.grayScale.lightNeutral0,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      boxShadow: '0px 0px 32px rgba(40, 40, 40, 0.32)',
      zIndex: 3,
    },
    buttonsContainer: {
      height: '113px',
      alignItems: 'center',
      paddingTop: '32px',
      paddingBottom: '24px',
    },
    cancelContainer: {
      borderTopWidth: 1,
      borderColor: colors.grayScale.lightNeutral60,
      height: '80px',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cancelText: {
      ...fonts.bold,
      color: colors.core.blue,
    },
    backdrop: {
      position: 'fixed',
      width: '100%',
      height: '100vh',
      top: 0,
      left: 0,
      opacity: 0.5,
      zIndex: 0,
    }
  });

  const {
    indexSettingsMenu: { hideIndexSettingsMenu },
    indexId: { indexId },
    indexName: { indexName },
    confirmDeleteIndexDialog: { showConfirmDeleteIndexDialog },
  } = useStore();

  const navigate = useNavigate();
  const user = useUserState();

  const userId = user.id;

  const indexSettingsButtonDataLayer = IndexSettingsTagManagerArgs(indexName, indexId!);
  const indexDeleteButtonDataLayer = DeleteIndexTagManagerArgs(indexName, indexId!);
  const indexCancelButtonDataLayer = CancelIndexMenuTagManagerArgs(indexName, indexId!);

  return (
    <>
      <View style={style.base}>
        <View style={style.buttonsContainer}>
          <Button onPress={() => { pushTagManagerLayer(indexSettingsButtonDataLayer); navigate(`/editIndex/${indexId}`); hideIndexSettingsMenu(); }} buttonText="Index settings" iconName="settingsIcon" type="primary" iconHeight={24} iconWidth={24} style={{width: '193px'}} />
        </View>

        <TouchableOpacity style={style.cancelContainer} onPress={() => { pushTagManagerLayer(indexCancelButtonDataLayer); hideIndexSettingsMenu(); }}>
          <Text style={style.cancelText}>Cancel</Text>
        </TouchableOpacity>
      </View>

      <TouchableOpacity style={style.backdrop} onPress={() => { pushTagManagerLayer(indexCancelButtonDataLayer); hideIndexSettingsMenu(); }} />
    </>
  )
}

export default IndexMenu;