import { ReactNode } from 'react';
import { ScrollView, View, Text, StyleSheet, ViewStyle } from 'react-native';
import { colors, fonts } from 'utils/constants';

interface CircleProps {
    style?: ViewStyle;
    children?: ReactNode;
    size: number;
    color: string;
}

export default function Circle({ style, children, size, color }: CircleProps) {
    const styles = StyleSheet.create({
        base: {
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: size,
            backgroundColor: color,
            justifyContent: 'center',
            alignItems: 'center',
            ...style
        },
    });

    return (
        <View style={styles.base}>{children ? children : null}</View>
    )
}