import { createContext, PropsWithChildren, useContext } from "react";
import { Indexes } from "./Indexes";

type ComponentWithChildProps = PropsWithChildren<{}>;

export const IndexContext = createContext<Indexes>(null as any);

export const IndexStoreProvider = ({ children }: ComponentWithChildProps) => {
  return <IndexContext.Provider value={new Indexes()}>{children}</IndexContext.Provider>
}

export const useIndexStore = () => useContext(IndexContext);