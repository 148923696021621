import Column from 'components/parts/Column';
import IndexIconCircle from 'components/parts/IndexIconCircle';
import SmallIndexCircle from 'components/parts/SmallIndexCircle';
import WeeklyReport, { Report } from 'components/Publish/WeeklyReport';
import { Formats, OutputFormats, RefillAssortmentTypeEnum, Resolutions } from 'enums';
import { DateTime } from 'luxon';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'utils/useNavigate';
import { getIndex, getIndexAggregation } from 'repository';
import { useGet } from 'repository/useGet';
import { colors, fonts } from 'utils/constants';
import { formatDateTime, generateDateStringFromTwoDates, getDateRange } from 'utils/dateHelpers';
import { useEffect, useState } from 'react';
import { IndexAggregation } from 'models/Index/IndexAggregation.model';
import { Index } from 'models/Index/Index.model';
import Button from 'components/parts/Button';
import Image from '../parts/Image';
import { PublicPageBackButtonTagManagerArgs, pushTagManagerLayer, ShareViewButtonTagManagerArgs } from 'utils/tagManagerArgs';

const Public = () => {
  const location = useLocation();
  const format = +(new URLSearchParams(location.search).get('format') || 0);
  const landscape = +(new URLSearchParams(location.search).get('landscape') || 0) === Formats.landscape;
  const pdf = +(new URLSearchParams(location.search).get('pdf') || 0) === OutputFormats.pdf;

  console.log(pdf);

  const style = StyleSheet.create({
    base: {
      margin: 'auto',
      alignItems: 'center',
    },
    wrapper: {
      paddingTop: '2em',
      width: '100%',
    },
    blueBackground: {
      width: '100%',
      height: '188px',
      backgroundColor: colors.core.cyan10,
      position: 'absolute',
      top: 0,
      zIndex: 0,
    },
    headWrapper: {
      padding: '2em',
    },
    cardContent: {
      padding: '1em',
      alignItems: 'center',
    },
    downloadButton: {
      marginTop: '1em',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopWidth: 1,
      padding: '1em',
      borderTopColor: colors.grayScale.lightNeutral60,
    },
    downloadButtonText: {
      ...fonts.bold,
      color: colors.core.blue,
      marginLeft: '1em',
    },
    descriptionText: {
      ...fonts.paragraph,
      marginTop: '2em',
    },
  });

  const { indexId, week } = useParams();

  const [report, setReport] = useState<Report[]>([]);
  const today = DateTime.local();

  const startDate = today.minus({ weeks: Number(week) }).startOf('week');
  const endDate = today.minus({ weeks: Number(week) }).endOf('week');

  const navigate = useNavigate();

  const { data: index } = useGet(['getIndexById'], () => getIndex(indexId || ''), {
    enabled: true,
  });

  const { data: aggregations } = useGet(
    ['getIndexAggregation', indexId],
    () => getIndexAggregation(indexId || '', Resolutions.week, formatDateTime(startDate)),
    {
      onSuccess: (data) => console.log(data),
    }
  );

  const getAveragePercent = (report: Report[]) => {
    let avg: string[] = [];

    report.forEach((day) => avg.push(day.icon));
    let avgColor = avg.sort((a, b) => avg.filter((v) => v === a).length - avg.filter((v) => v === b).length).pop();

    return avgColor?.substring(6) || 'Grey';
  };

  const generateReport = (aggregations: IndexAggregation[], index: Index) => {
    let icon = '';
    let days = getDateRange(startDate, endDate);

    let ds = days.map((day) => {
      // i'm so sorry this is so ugly
      let aggregation = aggregations.find((a) => DateTime.fromISO(a.aggregationStartAt).toFormat('yyyy-MM-dd') === day);

      if (aggregation) {
        if (today.toISO() < aggregation.aggregationStartAt) icon = 'circleGrey';
        else if (aggregation.progress >= index.greenThreshold) icon = 'circleGreen';
        else if (aggregation.progress <= index.yellowThreshold) icon = 'circleRed';
        else if (aggregation.progress > index.yellowThreshold && aggregation.progress < index.greenThreshold) icon = 'circleOrange';

        return {
          date: aggregation.aggregationStartAt,
          icon: icon,
        };
      } else {
        return {
          date: day,
          icon: 'circleGrey',
        };
      }
    });

    return ds;
  };

  // get an average from a list of days and return a corresponding color. ie green, yellow, red, or grey
  const getMainIconColor = (report: Report[]) => {
    let avg: string[] = [];

    report.forEach((day) => avg.push(day.icon));
    let avgColor = avg.sort((a, b) => avg.filter((v) => v === a).length - avg.filter((v) => v === b).length).pop();

    return avgColor?.substring(6) || 'Grey';
  };

  useEffect(() => {
    if (index) setReport(generateReport(aggregations || [], index));
  }, [aggregations, index]);

  return !index ? (
    <></>
  ) : (
    <View style={{width: '100%', height: '100%' }}>
      {pdf ? (
        <div className="noPrint" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <TouchableOpacity
            style={{ marginTop: '2em', marginLeft: '2em' }}
            key="noPrint"
            onPress={() => {
              pushTagManagerLayer(PublicPageBackButtonTagManagerArgs());
              navigate('/publish', 'Publish');
            }}>
            <Image imageName="backIcon" style={{ width: '8.66px', height: '16px' }} />
          </TouchableOpacity>

          <Button style={{ marginTop: '2em', marginLeft: '2em', marginRight: '2em' }} onPress={() => { pushTagManagerLayer(ShareViewButtonTagManagerArgs(format, index.message, index.indexName, index.guid)); window.print(); }} buttonText='Share' />
        </div>
      ) : null}

      {landscape ? (
        <ScrollView contentContainerStyle={style.base} horizontal>
          <IndexIconCircle
            color={getAveragePercent(report)}
            large
            greenThreshold={index.greenThreshold}
            yellowThreshold={index.yellowThreshold}
            indexId={index.guid}
            indexType={index.dispenserType as RefillAssortmentTypeEnum}
          />

          <View style={style.cardContent}>
            <Text style={{ ...fonts.headline4, color: colors.grayScale.darkNeutral100 }}>{index.indexName}</Text>

            <SmallIndexCircle
              dateRange={generateDateStringFromTwoDates(startDate, endDate)}
              indexId={index.guid}
              greenThreshold={index.greenThreshold}
              yellowThreshold={index.yellowThreshold}
            />

            <WeeklyReport report={report} />

            {/* <Stars indexId={index.bmIndexId} greenThreshold={index.greenThreshold} showDays style={{ marginTop: '2em' }} /> */}
            <Text style={style.descriptionText}>{index.message}</Text>
          </View>
        </ScrollView>
      ) : (
        <Column>
          <View style={style.cardContent}>
            <Text style={{ ...fonts.headline4, color: colors.grayScale.darkNeutral100 }}>{index.indexName}</Text>

            <IndexIconCircle
              color={getAveragePercent(report)}
              large
              greenThreshold={index.greenThreshold}
              yellowThreshold={index.yellowThreshold}
              indexId={index.guid}
              indexType={index.dispenserType as RefillAssortmentTypeEnum}
              marginTop="1em"
            />
            <SmallIndexCircle
              dateRange={generateDateStringFromTwoDates(startDate, endDate)}
              indexId={index.guid}
              greenThreshold={index.greenThreshold}
              yellowThreshold={index.yellowThreshold}
            />

            <WeeklyReport report={report} />

            <Text style={style.descriptionText}>{index.message}</Text>
          </View>
        </Column>
      )}
    </View>
  );
};

export default Public;
