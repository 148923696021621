import BarChart from 'components/parts/BarChart';
import ProgressBar from 'components/parts/ProgressBar';
import { BarChartResolutions, ReportTypes, Resolutions } from 'enums';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'utils/useNavigate';
import { getIndex, getIndexAggregation } from 'repository';
import { useGet } from 'repository/useGet';
import { accumulateAggregationsBasedOnResolution, BarChartData, calculateAggregationsAverageValue } from 'utils/aggregationHelpers';
import { colors, fonts } from 'utils/constants';
import { formatDateTime, generateDateStringFromTwoDates } from 'utils/dateHelpers';
import Image from 'components/parts/Image';
import Row from 'components/parts/Row';
import Button from 'components/parts/Button';
import { pushTagManagerLayer, ReportPageBackButtonTagManagerArgs, ShareReportButtonTagManagerArgs } from 'utils/tagManagerArgs';

const Report = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { indexId, flag } = useParams();

  const reportTypeFlag = flag !== undefined ? +flag : 0;
  const selectedIndexId = indexId !== undefined ? indexId : '00000000-0000-0000-0000-000000000000';

  const showYesterday = (reportTypeFlag & ReportTypes.yesterday) === ReportTypes.yesterday;
  const showLastWeek = (reportTypeFlag & ReportTypes.lastWeek) === ReportTypes.lastWeek;
  const showLastMonth = (reportTypeFlag & ReportTypes.lastMonth) === ReportTypes.lastMonth;
  const showThisYear = (reportTypeFlag & ReportTypes.soFarThisYear) === ReportTypes.soFarThisYear;

  const convertReportTypeToResolution = (reportType: ReportTypes) => {
    switch (reportType) {
      case ReportTypes.yesterday:
        return Resolutions.day;
      case ReportTypes.lastWeek:
        return Resolutions.week;
      case ReportTypes.lastMonth:
        return Resolutions.month;
      case ReportTypes.soFarThisYear:
        return Resolutions.year;
      default:
        return Resolutions.day;
    }
  };

  const style = StyleSheet.create({
    base: {
      display: 'flex',
      flexDirection: 'column',
    },
    baseContentContainer: {
      justifyContent: 'center',
      height: '100%',
    },
    row: {
      flexDirection: 'column',
      width: '100%',
      height: '842px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    miscContainer: {
      padding: '2em',
    },
    barchartContainer: {
      padding: '2em',
    },
    dateText: {
      ...fonts.preamble,
      color: colors.grayScale.darkNeutral80,
    },
    indexNameText: {
      ...fonts.headline2,
      color: colors.grayScale.darkNeutral100,
    },
  });

  interface IData {
    data: BarChartData[];
    label: string;
    average: number;
    passages: number;
    dispenses: number;
    datatype: Resolutions;
  }
  const today = DateTime.local();

  const [data, setData] = useState<IData>();
  const [interval, setInterval] = useState<Resolutions>(Resolutions.day);
  const [startDate, setStartDate] = useState<DateTime>(today);
  const [endDate, setEndDate] = useState<DateTime>(today);
  const [gtmReportType, setGtmReportType] = useState<string>("");

  useEffect(() => {
    if (showYesterday) {
      setInterval(Resolutions.day);
      setStartDate(today.minus({ day: 1 }).startOf('day'));
      setEndDate(today.minus({ day: 1 }).endOf('day'));
    } else if (showLastWeek) {
      setInterval(Resolutions.week);
      setStartDate(today.startOf('week').minus({ week: 1 }));
      setEndDate(today.endOf('week').minus({ week: 1 }));
    } else if (showLastMonth) {
      setInterval(Resolutions.month);
      setStartDate(today.startOf('month').minus({ month: 1 }));
      setEndDate(today.endOf('month').minus({ month: 1 }));
    } else if (showThisYear) {
      setInterval(Resolutions.year);
      setStartDate(today.startOf('year'));
      setEndDate(today);
    }
  }, []);


  const { data: indexData } = useGet(['getIndexById'], () => getIndex(indexId || ''), {
    enabled: true,
  });

  const indexName = indexData && indexData.indexName ? indexData.indexName : '';
  const locationAlias = indexData && indexData.locationName ? indexData.locationName : '';
  const greenThreshold = indexData && indexData.greenThreshold ? indexData.greenThreshold : 0;
  const yellowThreshold = indexData && indexData.yellowThreshold ? indexData.yellowThreshold : 0;

  const { data: indexes } = useGet(
    ['getIndexAggregations', interval, startDate],
    () => getIndexAggregation(selectedIndexId, interval, formatDateTime(startDate)),
    {
      onSuccess: (data) => console.log(data),
      onError: (err) => console.log({ interval, err }),
    }
  );

  useEffect(() => {
    let newData: IData;

    let res = BarChartResolutions.day;

    switch (interval) {
      case 'day':
        res = BarChartResolutions.day;
        setGtmReportType("Yesterday");
        break;
      case 'week':
        res = BarChartResolutions.week;
        setGtmReportType("Last week");
        break;
      case 'month':
        res = BarChartResolutions.month;
        setGtmReportType("Last month");
        break;
      case 'year':
        res = BarChartResolutions.year;
        setGtmReportType("This year so far");
        break;
    }

    newData = {
      data: accumulateAggregationsBasedOnResolution(indexes || [], res, today),
      label: today.minus({ [interval]: 1 }).toFormat('dd LLL yyyy'),
      average: calculateAggregationsAverageValue(indexes || []),
      passages: indexes?.reduce((total, curr) => total + curr.visitors, 0) || 0,
      dispenses: indexes?.reduce((total, curr) => total + curr.dispenses, 0) || 0,
      datatype: interval,
    };

    setData(newData);
  }, [indexes, interval]);

  if (flag === undefined || flag === '0' || indexId === undefined) {
    return null;
  }

  return (
    <ScrollView style={style.base} directionalLockEnabled={false} contentContainerStyle={style.baseContentContainer}>
      <div className="noPrint" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <TouchableOpacity
          style={{ marginTop: '2em', marginLeft: '2em' }}
          key="noPrint"
          onPress={() => {
            pushTagManagerLayer(ReportPageBackButtonTagManagerArgs());
            navigate('/detail/' + indexId, 'Index Details');
          }}>
          <Image imageName="backIcon" style={{ width: '8.66px', height: '16px' }} />
        </TouchableOpacity>

        <Button style={{ marginTop: '2em', marginLeft: '2em', marginRight: '2em' }} onPress={() => { pushTagManagerLayer(ShareReportButtonTagManagerArgs(gtmReportType, indexName, indexId)); window.print(); }} buttonText='Share' />
      </div>
      
      <ScrollView style={{ width: '100%', flex: 1 }} horizontal={true}>
        <Row style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <View style={style.miscContainer}>
            <Text style={style.dateText}>{generateDateStringFromTwoDates(startDate, endDate)}</Text>
            <Text style={style.dateText}>{indexName}</Text>
            <Text style={style.indexNameText}>{locationAlias}</Text>
            <ProgressBar
              style={{ alignSelf: 'center', marginTop: '2em' }}
              yesterdayProgress={data?.average || 0}
              todayProgress={0} // no today progress for report?
              backgroundColor={colors.grayScale.lightNeutral0}
              greenThreshold={greenThreshold}
              yellowThreshold={yellowThreshold}
              passages={data?.passages || 0}
              report={true}
            />
          </View>

          <View style={style.barchartContainer}>
            <BarChart
              dataset={data && data.data || []}
              datatype={data && data.datatype || Resolutions.day}
              greenLineAtNumber={greenThreshold}
              orangeLineAtNumber={yellowThreshold}
              report={true}
            />
          </View>
        </Row>
      </ScrollView>
    </ScrollView>
  );
};

export default Report;
