import { ReactNode } from 'react';
import { ScrollView, View, Text, StyleSheet, ViewStyle } from 'react-native';
import { colors, fonts } from 'utils/constants';

interface ColumnProps {
    children?: ReactNode,
    style?: ViewStyle
}

export default function Column({ children, style, ...props }: ColumnProps) {
    const styles = StyleSheet.create({
        base: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            ...style
        },
    });

    return (
        <View style={styles.base}>
            {children}
        </View>
    )
}