import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors, fonts } from 'utils/constants';
import Button from '../parts/Button';
import Radio from '../parts/Radio';
import { useStore } from 'utils/store';
import { ReportTypes } from 'enums';
import { useNavigate } from 'utils/useNavigate';
import { DownloadModalCancelButtonTagManagerArgs, DownloadModalDownloadButtonTagManagerArgs, pushTagManagerLayer } from 'utils/tagManagerArgs';
import { useUserState } from 'contexts/UserContext';

interface ReportPopupProps {
  indexId: string;
}

export default function ReportPopup({ indexId }: ReportPopupProps) {
  const {
    reportPopup: { hideReportPopup },
    reportType: { reportType, setReportType },
    indexName: { indexName },
  } = useStore();

  const style = StyleSheet.create({
    base: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: '0px 0px 32px rgba(40, 40, 40, 0.32)',
      height: '443px',
      width: '90%',
      left: '5%',
      top: '50%',
      transform: [{ translateY: -217 }],
      backgroundColor: colors.grayScale.lightNeutral0,
      borderRadius: 4,
      position: 'fixed',
      zIndex: 3,
    },
    radioContainer: {
      width: '80%',
      marginTop: '8px',
    },
    buttonsContainer: {
      width: '80%',
      marginTop: '32px',
    },
    backdrop: {
      position: 'fixed',
      width: '100%',
      height: '100vh',
      top: 0,
      left: 0,
      backgroundColor: colors.grayScale.darkNeutral80,
      opacity: 0.5,
      zIndex: 2,
    }
  });

  const navigate = useNavigate();
  const user = useUserState();

  const userId = user.id;

  const downloadButtonDataLayer = DownloadModalDownloadButtonTagManagerArgs(indexName, indexId, reportType);
  const cancelDownloadButtonDataLayer = DownloadModalCancelButtonTagManagerArgs(indexName, indexId);

  return (
    <>
      <View style={style.base} >
        <View style={{flexDirection: 'row'}}>
          <Text style={{...fonts.headline5, color: colors.grayScale.darkNeutral100, marginTop: '48px', paddingLeft: '2em', paddingRight: '2em' }}>Select which part you want to download</Text>
        </View>

        <View style={style.radioContainer}>
          <Radio text={"Yesterday"} value={ReportTypes.yesterday} type='circle' activeValue={reportType} setValue={setReportType} />
          <Radio text={"Last week"} value={ReportTypes.lastWeek} type='circle' activeValue={reportType} setValue={setReportType} />
          <Radio text={"Last month"} value={ReportTypes.lastMonth} type='circle' activeValue={reportType} setValue={setReportType} />
          <Radio text={"So far this year"} value={ReportTypes.soFarThisYear} type='circle' activeValue={reportType} setValue={setReportType} />
        </View>

        <View style={style.buttonsContainer}>
          <Button onPress={() => { pushTagManagerLayer(downloadButtonDataLayer); hideReportPopup(); navigate(`/report/${indexId}/${reportType}`, 'Index Report View')}} style={{ width: '212px' }} iconName={'downloadIcon'} iconWidth={13} iconHeight={16} buttonText={"Download report"} />

          <Button buttonText='Cancel' type="secondary" onPress={() => { pushTagManagerLayer(cancelDownloadButtonDataLayer); hideReportPopup(); }} style={{marginTop: '1em'}} />
        </View>

      </View>
      <TouchableOpacity style={style.backdrop} onPress={() => { pushTagManagerLayer(cancelDownloadButtonDataLayer); hideReportPopup(); }} />
    </>
  );
}