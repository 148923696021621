import { ReactNode } from 'react';
import { ScrollView, View, Text, StyleSheet, ViewStyle } from 'react-native';
import { colors, fonts } from 'utils/constants';

interface CardProps {
    children?: ReactNode,
    style?: ViewStyle
}

export default function Card({ children, style, ...props }: CardProps) {
    const styles = StyleSheet.create({
        base: {
            display: 'flex',
            flexDirection: 'column',
            filter: 'drop-shadow(0px 2px 10px rgba(40, 40, 40, 0.16))',
            backgroundColor: colors.grayScale.lightNeutral0,
            margin: '1em',
            marginTop: '0',
            marginBottom: '0',
            padding: '1em',
            paddingBottom: 0,
            borderRadius: 8,
            ...style
        },
    });

    return (
        <View style={styles.base}>
            {children}
        </View>
    )
}