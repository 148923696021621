import { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useNavigate } from 'utils/useNavigate';
import { getNote, putNote } from 'repository/notes';
import { usePost } from 'repository/usePost';
import { colors, fonts } from 'utils/constants';
import CustomTextInput from 'components/parts/TextInput';
import Radio from 'components/parts/Radio';
import Button from 'components/parts/Button';
import Row from 'components/parts/Row';
import { useQueryClient } from 'react-query';
import { useGet } from 'repository/useGet';
import { getUserSites } from 'repository/account';
import Dropdown from 'components/parts/Dropdown';
import { Site } from 'models/Index/Site.model';
import { useStore } from 'utils/store';
import { EditNoteSaveButtonTagManagerArgs, pushTagManagerLayer } from 'utils/tagManagerArgs';

type Props = {};

const style = StyleSheet.create({
  base: {
    paddingBottom: '100px',
  },
  wrapper: {
    width: '100%',
    paddingTop: '3em',
    marginBottom: '100px',
    padding: '2em',
  },
  topWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '2em',
    paddingBottom: '0',
  },
});

type Params = {
  noteId?: string;
};

const Note: React.FC<Props> = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    noteId: { noteId },
  } = useStore();

  const [noteTitle, setNoteTitle] = useState('');
  const [noteBody, setNoteBody] = useState('');
  const [isNotePrivate, setIsNotePrivate] = useState<boolean>(false);
  const [userSites, setUserSites] = useState<Site[]>([]);
  const [selectedSite, setSelectedSite] = useState<number>(0);

  const [initialNoteTitle, setInitialNoteTitle] = useState('');
  const [initialNoteBody, setInitialNoteBody] = useState('');
  const [initialIsNotePrivate, setInitialIsNotePrivate] = useState<boolean>(false);

  const [noteHasChanged, setNoteHasChanged] = useState(false);

  const { data: note } = useGet(['getNote', noteId], () => getNote(noteId || '0'));


  useEffect(() => {
    if (note) {
      setNoteTitle(note.title);
      setNoteBody(note.body);
      setIsNotePrivate(note.private);
      setSelectedSite(note.siteId);

      setInitialIsNotePrivate(note.private);
      setInitialNoteBody(note.body);
      setInitialNoteTitle(note.title);
    }
  }, [note]);

  useEffect(() => {
    if (noteTitle !== initialNoteTitle || noteBody !== initialNoteBody || isNotePrivate !== initialIsNotePrivate) {
      setNoteHasChanged(true);
    } else {
      setNoteHasChanged(false);
    }
  }, [noteTitle, noteBody, isNotePrivate]);

  useGet(['getUserSites'], () => getUserSites(), {
    onSuccess: (data) => {
      console.log('yay my sites:', data);
      
      if (data.length > 1) {
        data = [...data];
      }

      setUserSites(data);
    },
    onError: (error) => console.log(error),
  });

  const createNote = usePost(() => putNote({guid: noteId, body: noteBody, title: noteTitle, private: isNotePrivate, siteId: selectedSite }),
    (data) => {
      console.log(data);
      
      queryClient.invalidateQueries('getNotes')
      navigate('/notes', 'Notes');
    },
    (error) => console.log(error)
  );

  const handleEditNote = () => {
    pushTagManagerLayer(EditNoteSaveButtonTagManagerArgs(noteTitle, noteBody, isNotePrivate ? 'Private' : 'Public', userSites.find((site) => site.siteId === selectedSite)?.siteName || 'None', noteHasChanged));
    createNote.mutate({body: noteBody, title: noteTitle, private: isNotePrivate});
  };

  return (
    <ScrollView style={style.base}>
      <View style={style.wrapper}>
        <Text style={{ ...fonts.headline1, color: colors.grayScale.darkNeutral100 }}>Edit Note</Text>

        <View>
          <View style={{ marginTop: 18 }}>
            <Text style={{ ...fonts.medium, color: colors.grayScale.darkNeutral100 }}>Note title</Text>
            <CustomTextInput value={noteTitle} setValue={setNoteTitle} placeholder="Title.." />
          </View>

          <View style={{ marginTop: 16 }}>
            <Text style={{ ...fonts.medium, color: colors.grayScale.darkNeutral100 }}>Note</Text>
            <CustomTextInput value={noteBody} setValue={setNoteBody} placeholder="Write your note here.." maxLength={200} height={200} />
            <Text style={{ ...fonts.small, color: colors.grayScale.darkNeutral100, textAlign: 'right', marginTop: 8 }}>{noteBody.length}/200</Text>
          </View>

          <View style={{ marginTop: 16 }}>
            <Text style={{ ...fonts.medium, color: colors.grayScale.darkNeutral100 }}>This note is..</Text>
            <Row>
              <Radio value={false} setValue={() => setIsNotePrivate(false)} text="Public" activeValue={isNotePrivate} type="circle" />
              <Radio value={true} setValue={() => setIsNotePrivate(true)} text="Private" activeValue={isNotePrivate} type="circle" style={{ marginLeft: 24 }} />
            </Row>
          </View>

          {userSites.length > 1 && (
            <View style={{ marginTop: 32, zIndex: 5 }}>
              <Text style={{ ...fonts.medium, color: colors.grayScale.darkNeutral100 }}>Associated with site</Text>
              <Dropdown<number>
                options={userSites.map((site) => ({ label: site.siteName, value: site.siteId }))}
                selectedOption={selectedSite}
                setSelectedOption={setSelectedSite}
                defaultOption={0}
              />
            </View>
          )}

          <View style={{ marginTop: 40, zIndex: 0 }}>
            <Row style={{ justifyContent: 'space-between' }}>
              <Button type='secondary' buttonText='Cancel' onPress={() => navigate('/notes', 'Notes')} />
              <Button type='primary' buttonText='Save' onPress={() => handleEditNote()} disabled={selectedSite === 0 || noteTitle === ''} />
            </Row>
          </View>
        </View>
      </View>
    </ScrollView>
  )
};

export default Note;
