import { Formats, RefillAssortmentTypeEnum, ReportTypes } from 'enums';

// Global
export const globalTagManagerArgs = (userId: string) => ({
  userId: userId,
});

export const ChangePageViewTagManagerArgs = (pageUrl: string, pageTitle: string) => ({
  event: 'virtualPageview',
  pageUrl: pageUrl,
  pageTitle: pageTitle,
});

// Login
export const loginTagManagerArgs = () => ({
  event: 'login',
  method: 'tork_vision_account',
});

// Index Creation Wizard Page 1
export const IndexCreationWizard01TagManagerArgs = (
  siteName: string,
  locationName: string,
  indexType: RefillAssortmentTypeEnum,
  indexName: string
) => ({
  event: 'buttonCreateIndex01',
  siteName: siteName,
  locationName: locationName,
  indexType: indexType,
  indexName: indexName,
  stringValue: 'Continue',
  elementId: 'create_index_01',
});

export const IndexCreationWizard01CancelTagManagerArgs = () => ({
  event: 'buttonClick',
  stringValue: 'Cancel',
  elementId: 'cancel_create_index_01',
});

// Index Creation Wizard Page 2
export const IndexCreationWizard02TagManagerArgs = (
  startDate: string,
  startTime: string,
  timeInteraval: string,
  thresholdGreen: number,
  thresholdOrange: number,
  indexName: string
) => ({
  event: 'buttonCreateIndex02',
  startDate: startDate,
  startTime: startTime,
  timeInteraval: timeInteraval,
  thresholdGreen: thresholdGreen,
  thresholdOrange: thresholdOrange,
  indexName: indexName,
  stringValue: 'Create index',
  elementId: 'create_index_02',
});

export const IndexCreationWizard02BackTagManagerArgs = () => ({
  event: 'buttonClick',
  stringValue: 'Back',
  elementId: 'cancel_create_index_02',
});

// Edit Index Settings
export const EditIndexSettingsTagManagerArgs = (
  greenThreshold: number,
  orangeThreshold: number,
  indexName: string,
  indexId: string
) => ({
  event: 'buttonUpdateIndex',
  thresholdGreen: greenThreshold,
  thresholdOrange: orangeThreshold,
  indexName: indexName,
  indexId: indexId,
  stringValue: 'Continue',
  elementId: 'update_index',
});

export const EditIndexSettingsCancelTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'Cancel',
  elementId: 'cancel_index_settings_01',
  indexName: indexName,
  indexId: indexId,
});

// Dashboard
export const QuickActionTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'Quick Action',
  elementId: 'quick_action_01',
  indexName: indexName,
  indexId: indexId,
});

export const ViewDetailsTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'View details',
  elementId: 'view_details_01',
  indexName: indexName,
  indexId: indexId,
});

export const CreateNewIndexTagManagerArgs = () => ({
  event: 'buttonClick',
  stringValue: 'Create new index',
  elementId: 'create_new_index_button_01',
});

export const NavBarDashboardTagManagerArgs = () => ({
  event: 'navBar',
  stringValue: 'Dashboard',
  elementId: 'nav_bar_01',
});

export const IndexSettingsTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'Index settings',
  elementId: 'index_settings_01',
  indexName: indexName,
  indexId: indexId,
});

export const DeleteIndexTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'Delete index',
  elementId: 'delete_index_01',
  indexName: indexName,
  indexId: indexId,
});

export const CancelIndexMenuTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'Cancel',
  elementId: 'cancel_01',
  indexName: indexName,
  indexId: indexId,
});

// Dashboard delete index confirmation modal
export const DeleteIndexConfirmationTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'Delete',
  elementId: 'delete_01',
  indexName: indexName,
  indexId: indexId,
});

export const DeleteIndexConfirmationCancelButtonTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'Cancel',
  elementId: 'cancel_03',
  indexName: indexName,
  indexId: indexId,
});

// Pop-up Log Out
export const PopUpLogOutButtonTagManagerArgs = () => ({
  event: 'buttonClick',
  stringValue: 'Log out',
  elementId: 'log_out_01',
});

export const PopUpLogOutCancelButtonTagManagerArgs = () => ({
  event: 'buttonClick',
  stringValue: 'Cancel',
  elementId: 'cancel_05',
});

// Index Detail
export const DispenserStatusFullTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'dispenserStatus',
  dispenserType: 'Full',
  indexName: indexName,
  indexId: indexId,
});

export const DispenserStatusEmptyTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'dispenserStatus',
  dispenserType: 'Empty',
  indexName: indexName,
  indexId: indexId,
});

export const IndexDetailPassagesTagManagerArgs = (passages: number, indexName: string, indexId: string) => ({
  event: 'passages',
  indexValue: passages,
  indexName: indexName,
  indexId: indexId,
});

export const IndexDetailDownloadButtonTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'Download',
  indexName: indexName,
  indexId: indexId,
});

export const NavIndexOverviewDayTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'navIndexOverview',
  stringValue: 'Day',
  indexName: indexName,
  indexId: indexId,
});

export const NavIndexOverviewWeekTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'navIndexOverview',
  stringValue: 'Week',
  indexName: indexName,
  indexId: indexId,
});

export const NavIndexOverviewMonthTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'navIndexOverview',
  stringValue: 'Month',
  indexName: indexName,
  indexId: indexId,
});

export const NavIndexOverviewYearTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'navIndexOverview',
  stringValue: 'Year',
  indexName: indexName,
  indexId: indexId,
});

export const ToggleOverviewLeftTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  elementId: 'toggle_overview_left',
  indexName: indexName,
  indexId: indexId,
});

export const ToggleOverviewRightTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  elementId: 'toggle_overview_right',
  indexName: indexName,
  indexId: indexId,
});

// Index Detail - Download Modal
export const DownloadModalDownloadButtonTagManagerArgs = (indexName: string, indexId: string, reportType: ReportTypes) => {
  const stringReportTypes = [];

  if (reportType === ReportTypes.yesterday) {
    stringReportTypes.push('Yesterday');
  }
  if (reportType === ReportTypes.lastWeek) {
    stringReportTypes.push('Last week');
  }
  if (reportType === ReportTypes.lastMonth) {
    stringReportTypes.push('Last month');
  }
  if (reportType === ReportTypes.soFarThisYear) {
    stringReportTypes.push('So far this year');
  }

  return {
    event: 'buttonDownloadReport',
    reportType: stringReportTypes.join(', '),
    indexName: indexName,
    indexId: indexId,
  };
};

export const DownloadModalCancelButtonTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'Cancel',
  indexName: indexName,
  indexId: indexId,
  elementId: 'cancel_05',
  pageTitle: 'Download',
});

// Publish Page
export const PublishPageSwitchWeekTabTagManagerArgs = (tab: string) => ({
  event: 'navPublish',
  stringValue: tab,
  pageTitle: 'Publish',
});

// Publish Modal
export const PublishModalCopyUrlButtonTagManagerArgs = (
  indexName: string,
  indexId: string,
  viewFormat: Formats,
  viewMessage: string
) => ({
  event: 'buttonCopyUrl',
  viewFormat: viewFormat,
  viewOutput: 'Web URL',
  viewMessage: viewMessage,
  indexName: indexName,
  indexId: indexId,
});

export const PublishModalCopyUrlAgainButtonTagManagerArgs = (
  indexName: string,
  indexId: string,
  viewFormat: Formats,
  viewMessage: string
) => ({
  event: 'buttonCopyUrlAgain',
  viewFormat: viewFormat,
  viewOutput: 'Web URL (Again)',
  viewMessage: viewMessage,
  indexName: indexName,
  indexId: indexId,
});

export const PublishModalPreviewButtonTagManagerArgs = (
  indexName: string,
  indexId: string,
  viewFormat: Formats,
  viewMessage: string
) => ({
  event: 'buttonDownloadView',
  viewFormat: viewFormat,
  viewOutput: 'PDF',
  viewMessage: viewMessage,
  indexName: indexName,
  indexId: indexId,
});

export const PublishModalCancelButtonTagManagerArgs = (indexName: string, indexId: string) => ({
  event: 'buttonClick',
  stringValue: 'Cancel',
  indexName: indexName,
  indexId: indexId,
  elementId: 'cancel_05',
  pageTitle: 'Publish',
});

export const MenuDashboardTagManagerArgs = () => ({
  event: 'navBar',
  stringValue: 'Dashboard',
});

export const MenuNotesTagManagerArgs = () => ({
  event: 'navBar',
  stringValue: 'Notes',
});

export const MenuPublishTagManagerArgs = () => ({
  event: 'navBar',
  stringValue: 'Publish',
});

export const LogoutTagManagerArgs = () => ({
  event: 'navBar',
  stringValue: 'Logout',
});

// Notes

export const CreateNewNoteTagManagerArgs = () => ({
  event: 'buttonClick',
  stringValue: 'New Note',
  pageTitle: 'Notes',
});

export const CancelNoteMenuTagManagerArgs = () => ({
  event: 'buttonNoteMenuCancel',
  stringValue: 'Cancel',
  pageTitle: 'Notes',
});

export const DeleteNoteTagManagerArgs = () => ({
  event: 'buttonClick',
  stringValue: 'Delete Note',
});

export const NoteSettingsTagManagerArgs = () => ({
  event: 'buttonClick',
  stringValue: 'Note Settings',
});

export const DeleteNoteConfirmationCancelButtonTagManagerArgs = () => ({
  event: 'buttonClick',
  stringValue: 'Cancel',
});

export const DeleteNoteConfirmationTagManagerArgs = () => ({
  event: 'buttonClick',
  stringValue: 'Delete Note',
});

export const SaveNewNoteTagManagerArgs = (noteTitle: string, noteText: string, noteStatus: string, noteAssociatedSite: string) => ({
  event: 'buttonNewNoteSave',
  noteTitle: noteTitle,
  noteText: noteText,
  noteStatus: noteStatus,
  noteAssociatedSite: noteAssociatedSite,
  pageTitle: 'Notes',
});

export const CancelNewNoteTagManagerArgs = () => ({
  event: 'buttonNewNoteCancel',
  stringValue: 'Cancel',
  pageTitle: 'Notes',
});

export const EditNoteButtonTagManagerArgs = (noteTitle: string, noteStatus: string, noteAssociatedSite: string) => ({
  event: 'buttonNoteEdit',
  noteTitle: noteTitle,
  noteStatus: noteStatus,
  noteAssociatedSite: noteAssociatedSite,
  pageTitle: 'Notes',
});

export const EditNoteSaveButtonTagManagerArgs = (noteTitle: string, noteText: string, noteStatus: string, noteAssociatedSite: string, changed: boolean) => ({
  event: 'buttonNoteEditSave',
  noteTitle: noteTitle,
  noteText: noteText,
  noteStatus: noteStatus,
  noteAssociatedSite: noteAssociatedSite,
  pageTitle: 'Notes',
  noteChange: changed,
});

export const PinNoteButtonTagManagerArgs = (noteTitle: string, pinStatus: boolean) => ({
  event: 'buttonNotePin',
  noteTitle: noteTitle,
  pinStatus: pinStatus,
  pageTitle: 'Notes',
});

// More stuff

export const ShareReportButtonTagManagerArgs = (reportType: string, indexName: string, indexId: string) => ({
  event: 'shareReport',
  reportType: reportType,
  indexName: indexName,
  indexId: indexId,
});

export const ShareViewButtonTagManagerArgs = (viewFormat: Formats, viewMessage: string, indexName: string, indexId: string) => ({
  event: 'shareView',
  viewFormat: viewFormat,
  viewOutput: 'PDF',
  viewMessage: viewMessage,
  indexName: indexName,
  indexId: indexId,
});

export const ReportPageBackButtonTagManagerArgs = () => ({
  event: 'buttonBack',
  pageTitle: 'Index Details',
});

export const PublicPageBackButtonTagManagerArgs = () => ({
  event: 'buttonBack',
  pageTitle: 'Publish',
});

export const pushTagManagerLayer = (args: any) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(args);
};

export const push = (args: {}) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(args);
};

export const gtm = {
  push,
};
