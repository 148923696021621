import { User } from 'interfaces/User.model';
import React, { useContext, useState } from 'react';
import { loadState } from 'utils/storage';

const initialState: User = {
  id: '0',
  email: 'test@test.com',
  name: 'bobby',
};

const UserStateContext = React.createContext<User | undefined>(initialState);

type UserProviderProps = {
  children: React.ReactNode;
};

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [userState, setUserState] = useState<User>();
  const [userId, setUserId] = useState<string>(loadState({ key: 'userId' }) || '0');

  // const { refetch: refetchUser } = useGet(['getUser', userId], (context) => getUser(String(context.queryKey[1])), {
  //   enabled: true,
  //   onSuccess: (data) => {
  //     setUserState(data);
  //     setUserId(data.id);
  //   },
  // });

  // useEffect(() => {
  //   if (!!userId) refetchUser();
  // }, [userId, refetchUser]);

  // useEffect(() => {
  //   setUserState(initialState);
  // }, [userId]);

  // useEffect(() => {
  //   console.log(userState);
  // }, [userState]);

  return <UserStateContext.Provider value={userState}>{children}</UserStateContext.Provider>;
};

function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUserState };
