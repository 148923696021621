import Donuts from 'donuts/donuts';

export const iconMappings: any = {
  dispenser: {
    png: {
      source: require('./icons/dispenser-icon.png'),
    },
    svg: {
      source: require('./icons/dispenser-icon.svg'),
    },
  },
  downloadIcon: {
    png: {
      source: require('./icons/download-icon.png'),
    },
    svg: {
      source: require('./icons/download-icon.png'),
    },
  },
  downloadIconBlue: {
    png: {
      source: require('./icons/download-icon-blue.png'),
    },
    svg: {
      source: require('./icons/download-icon-blue.png'),
    },
  },
  'downloadIcon-disabled': {
    png: {
      source: require('./icons/download-icon-disabled.png'),
    },
    svg: {
      source: require('./icons/download-icon-disabled.png'),
    },
  },
  nextIconCircle: {
    png: {
      source: require('./icons/next-icon-circle.png'),
    },
    svg: {
      source: require('./icons/next-icon-circle.png'),
    },
  },
  nextIconCircleCyan: {
    png: {
      source: require('./icons/next-icon-circle-cyan.png'),
    },
    svg: {
      source: require('./icons/next-icon-circle-cyan.png'),
    },
  },
  nextIconCircleBlue: {
    png: {
      source: require('./icons/next-icon-circle.png')
    },
    svg: {
      source: require('./icons/next-icon-circle.png')
    }
  },
  'nextIconCircle-disabled': {
    png: {
      source: require('./icons/next-icon-circle-disabled.png')
    },
    svg: {
      source: require('./icons/next-icon-circle-disabled.png')
    }
  },
  previousIconCircle: {
    png: {
      source: require('./icons/previous-icon-circle.png'),
    },
    svg: {
      source: require('./icons/previous-icon-circle.png'),
    },
  },
  'previousIconCircle-disabled': {
    png: {
      source: require('./icons/previous-icon-circle-disabled.png'),
    },
    svg: {
      source: require('./icons/previous-icon-circle-disabled.png'),
    },
  },
  previousIcon: {
    png: {
      source: require('./icons/previous-icon.png'),
    },
    svg: {
      source: require('./icons/previous-icon.png'),
    },
  },
  helpIcon: {
    png: {
      source: require('./icons/help-icon.png'),
    },
    svg: {
      source: require('./icons/help-icon.png'),
    },
  },
  homeIcon: {
    png: {
      source: require('./icons/home-icon.png'),
    },
    svg: {
      source: require('./icons/home-icon.png'),
    },
  },
  notesIcon: {
    png: {
      source: require('./icons/notes-icon.png')
    },
    svg: {
      source: require('./icons/notes-icon.png')
    }
  },
  pinnedIcon: {
    png: {
      source: require('./icons/pinned-icon.png')
    },
    svg: {
      source: require('./icons/pinned-icon.png')
    }
  },
  unpinnedIcon: {
    png: {
      source: require('./icons/unpinned-icon.png')
    },
    svg: {
      source: require('./icons/unpinned-icon.png')
    }
  },
  soapIcon: {
    png: {
      source: require('./icons/soap-icon.png'),
    },
    svg: {
      source: require('./icons/soap-icon.png'),
    },
  },
  sanitizerIcon: {
    png: {
      source: require('./icons/sanitizer-icon.png'),
    },
    svg: {
      source: require('./icons/sanitizer-icon.png'),
    },
  },
  checkmarkIcon: {
    png: {
      source: require('./icons/checkmark-icon.png'),
    },
    svg: {
      source: require('./icons/checkmark-icon.png'),
    },
  },
  crossIcon: {
    png: {
      source: require('./icons/cross-icon.png'),
    },
    svg: {
      source: require('./icons/cross-icon.png'),
    },
  },
  starIconBlue: {
    png: {
      source: require('./icons/star-icon-blue.png'),
    },
    svg: {
      source: require('./icons/star-icon-blue.png'),
    },
  },
  starIconBlueCircle: {
    png: {
      source: require('./icons/star-icon-blue-circle.png'),
    },
    svg: {
      source: require('./icons/star-icon-blue-circle.png'),
    },
  },
  'starIcon-disabled': {
    png: {
      source: require('./icons/star-icon-disabled.png'),
    },
    svg: {
      source: require('./icons/star-icon-disabled.png'),
    },
  },
  copyIcon: {
    png: {
      source: require('./icons/copy-icon.png'),
    },
    svg: {
      source: require('./icons/copy-icon.png'),
    },
  },
  torkLogo: {
    png: {
      source: require('./icons/tork-logo.png'),
    },
    svg: {
      source: require('./icons/tork-logo.png'),
    },
  },
  checkboxIcon: {
    png: {
      source: require('./icons/checkbox-icon.png'),
    },
    svg: {
      source: require('./icons/checkbox-icon.png'),
    },
  },
  settingsIcon: {
    png: {
      source: require('./icons/settings-icon.png'),
    },
    svg: {
      source: require('./icons/settings-icon.png'),
    },
  },
  settingsIconBlue: {
    png: {
      source: require('./icons/settings-icon-blue.png'),
    },
    svg: {
      source: require('./icons/settings-icon-blue.png'),
    },
  },
  deleteIcon: {
    png: {
      source: require('./icons/delete-icon.png'),
    },
    svg: {
      source: require('./icons/delete-icon.png'),
    },
  },
  backIcon: {
    png: {
      source: require('./icons/back-icon.png'),
    },
    svg: {
      source: require('./icons/back-icon.png'),
    },
  },
  moreIcon: {
    png: {
      source: require('./icons/more-icon.png'),
    },
    svg: {
      source: require('./icons/more-icon.png'),
    },
  },
  alertIcon: {
    png: {
      source: require('./icons/alert-icon.png'),
    },
    svg: {
      source: require('./icons/alert-icon.png'),
    },
  },
  alertIconRed: {
    png: {
      source: require('./icons/alert-icon-red.png'),
    },
    svg: {
      source: require('./icons/alert-icon-red.png'),
    },
  },
  lockIcon: {
    png: {
      source: require('./icons/lock.png'),
    },
    svg: {
      source: require('./icons/lock.png'),
    }
  },
  circleGrey: {
    png: {
      source: require('./icons/circle-grey.png'),
    },
    svg: {
      source: require('./icons/circle-grey.png'),
    },
  },
  circleGreen: {
    png: {
      source: require('./icons/circle-green.png'),
    },
    svg: {
      source: require('./icons/circle-green.png'),
    },
  },
  circleRed: {
    png: {
      source: require('./icons/circle-red.png'),
    },
    svg: {
      source: require('./icons/circle-red.png'),
    },
  },
  circleOrange: {
    png: {
      source: require('./icons/circle-orange.png'),
    },
    svg: {
      source: require('./icons/circle-orange.png'),
    },
  },
  signoutIcon: {
    png: {
      source: require('./icons/signout-icon.png'),
    },
    svg: {
      source: require('./icons/signout-icon.png'),
    },
  },
  eyeIcon: {
    png: {
      source: require('./icons/eye-icon.png'),
    },
    svg: {
      source: require('./icons/eye-icon.png'),
    },
  },
  eyeIconWhite: {
    png: {
      source: require('./icons/eye-icon-white.png'),
    },
    svg: {
      source: require('./icons/eye-icon-white.png'),
    },
  },
  handSoapIcon: {
    png: {
      source: require('./icons/hand-soap-icon.png'),
    },
    svg: {
      source: require('./icons/hand-soap-icon.png'),
    },
  },

  // repeat below code ten times. Prefix object key with "donut-green-x" as a string, and require files as "./donuts/green-x.png"
  // #region GREEN DONUTS

  'donut-green-1': {
    png: {
      source: require('./donuts/green-1.png'),
    },
    svg: {
      source: require('./donuts/green-1.png'),
    },
  },
  'donut-green-2': {
    png: {
      source: require('./donuts/green-2.png'),
    },
    svg: {
      source: require('./donuts/green-2.png'),
    },
  },
  'donut-green-3': {
    png: {
      source: require('./donuts/green-3.png'),
    },
    svg: {
      source: require('./donuts/green-3.png'),
    },
  },
  'donut-green-4': {
    png: {
      source: require('./donuts/green-4.png'),
    },
    svg: {
      source: require('./donuts/green-4.png'),
    },
  },
  'donut-green-5': {
    png: {
      source: require('./donuts/green-5.png'),
    },
    svg: {
      source: require('./donuts/green-5.png'),
    },
  },
  'donut-green-6': {
    png: {
      source: require('./donuts/green-6.png'),
    },
    svg: {
      source: require('./donuts/green-6.png'),
    },
  },
  'donut-green-7': {
    png: {
      source: require('./donuts/green-7.png'),
    },
    svg: {
      source: require('./donuts/green-7.png'),
    },
  },
  'donut-green-8': {
    png: {
      source: require('./donuts/green-8.png'),
    },
    svg: {
      source: require('./donuts/green-8.png'),
    },
  },
  'donut-green-9': {
    png: {
      source: require('./donuts/green-9.png'),
    },
    svg: {
      source: require('./donuts/green-9.png'),
    },
  },
  'donut-green-10': {
    png: {
      source: require('./donuts/green-10.png'),
    },
    svg: {
      source: require('./donuts/green-10.png'),
    },
  },
  // #endregion GREEN DONUTS

  // #region RED DONUTS

  'donut-red-1': {
    png: {
      source: require('./donuts/red-1.png'),
    },
    svg: {
      source: require('./donuts/red-1.png'),
    },
  },
  'donut-red-2': {
    png: {
      source: require('./donuts/red-2.png'),
    },
    svg: {
      source: require('./donuts/red-2.png'),
    },
  },
  'donut-red-3': {
    png: {
      source: require('./donuts/red-3.png'),
    },
    svg: {
      source: require('./donuts/red-3.png'),
    },
  },
  'donut-red-4': {
    png: {
      source: require('./donuts/red-4.png'),
    },
    svg: {
      source: require('./donuts/red-4.png'),
    },
  },
  'donut-red-5': {
    png: {
      source: require('./donuts/red-5.png'),
    },
    svg: {
      source: require('./donuts/red-5.png'),
    },
  },
  'donut-red-6': {
    png: {
      source: require('./donuts/red-6.png'),
    },
    svg: {
      source: require('./donuts/red-6.png'),
    },
  },
  'donut-red-7': {
    png: {
      source: require('./donuts/red-7.png'),
    },
    svg: {
      source: require('./donuts/red-7.png'),
    },
  },
  'donut-red-8': {
    png: {
      source: require('./donuts/red-8.png'),
    },
    svg: {
      source: require('./donuts/red-8.png'),
    },
  },
  'donut-red-9': {
    png: {
      source: require('./donuts/red-9.png'),
    },
    svg: {
      source: require('./donuts/red-9.png'),
    },
  },
  'donut-red-10': {
    png: {
      source: require('./donuts/red-10.png'),
    },
    svg: {
      source: require('./donuts/red-10.png'),
    },
  },

  // #endregion RED DONUTS

  // #region YELLOW DONUTS

  'donut-yellow-1': {
    png: {
      source: require('./donuts/yellow-1.png'),
    },
    svg: {
      source: require('./donuts/yellow-1.png'),
    },
  },
  'donut-yellow-2': {
    png: {
      source: require('./donuts/yellow-2.png'),
    },
    svg: {
      source: require('./donuts/yellow-2.png'),
    },
  },
  'donut-yellow-3': {
    png: {
      source: require('./donuts/yellow-3.png'),
    },
    svg: {
      source: require('./donuts/yellow-3.png'),
    },
  },
  'donut-yellow-4': {
    png: {
      source: require('./donuts/yellow-4.png'),
    },
    svg: {
      source: require('./donuts/yellow-4.png'),
    },
  },
  'donut-yellow-5': {
    png: {
      source: require('./donuts/yellow-5.png'),
    },
    svg: {
      source: require('./donuts/yellow-5.png'),
    },
  },
  'donut-yellow-6': {
    png: {
      source: require('./donuts/yellow-6.png'),
    },
    svg: {
      source: require('./donuts/yellow-6.png'),
    },
  },
  'donut-yellow-7': {
    png: {
      source: require('./donuts/yellow-7.png'),
    },
    svg: {
      source: require('./donuts/yellow-7.png'),
    },
  },
  'donut-yellow-8': {
    png: {
      source: require('./donuts/yellow-8.png'),
    },
    svg: {
      source: require('./donuts/yellow-8.png'),
    },
  },
  'donut-yellow-9': {
    png: {
      source: require('./donuts/yellow-9.png'),
    },
    svg: {
      source: require('./donuts/yellow-9.png'),
    },
  },
  'donut-yellow-10': {
    png: {
      source: require('./donuts/yellow-10.png'),
    },
    svg: {
      source: require('./donuts/yellow-10.png'),
    },
  },

  // #endregion YELLOW DONUTS

  // #region TODAY DONUTS
  'donut-today-empty': {
    png: {
      source: Donuts.donutImageTodayEmpty,
    },
    svg: {
      source: Donuts.donutImageTodayEmpty,
    },
  },
  'donut-today-0': {
    png: {
      source: Donuts.donutImageToday0,
    },
    svg: {
      source: Donuts.donutImageToday0,
    },
  },
  'donut-today-1': {
    png: {
      source: Donuts.donutImageToday1,
    },
    svg: {
      source: Donuts.donutImageToday1,
    },
  },
  'donut-today-2': {
    png: {
      source: Donuts.donutImageToday2,
    },
    svg: {
      source: Donuts.donutImageToday2,
    },
  },
  'donut-today-3': {
    png: {
      source: Donuts.donutImageToday3,
    },
    svg: {
      source: Donuts.donutImageToday3,
    },
  },
  'donut-today-4': {
    png: {
      source: Donuts.donutImageToday4,
    },
    svg: {
      source: Donuts.donutImageToday4,
    },
  },
  'donut-today-5': {
    png: {
      source: Donuts.donutImageToday5,
    },
    svg: {
      source: Donuts.donutImageToday5,
    },
  },
  'donut-today-6': {
    png: {
      source: Donuts.donutImageToday6,
    },
    svg: {
      source: Donuts.donutImageToday6,
    },
  },
  'donut-today-7': {
    png: {
      source: Donuts.donutImageToday7,
    },
    svg: {
      source: Donuts.donutImageToday7,
    },
  },
  'donut-today-8': {
    png: {
      source: Donuts.donutImageToday8,
    },
    svg: {
      source: Donuts.donutImageToday8,
    },
  },
  'donut-today-9': {
    png: {
      source: Donuts.donutImageToday9,
    },
    svg: {
      source: Donuts.donutImageToday9,
    },
  },
  'donut-today-10': {
    png: {
      source: Donuts.donutImageToday10,
    },
    svg: {
      source: Donuts.donutImageToday10,
    },
  },

  // #endregion TODAY DONUTS

  'donut-empty': {
    png: {
      source: require('./donuts/empty.png'),
    },
    svg: {
      source: require('./donuts/empty.png'),
    },
  },
};
