import Button from "components/parts/Button";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-dom";
import { colors, fonts } from "utils/constants";
import { useStore } from "utils/store";
import { CancelNoteMenuTagManagerArgs, DeleteNoteTagManagerArgs, EditNoteButtonTagManagerArgs, pushTagManagerLayer } from "utils/tagManagerArgs";

const NoteMenu = () => {
  const style = StyleSheet.create({
    base: {
      position: "absolute",
      bottom: 0,
      height: '249px',
      width: '100%',
      backgroundColor: colors.grayScale.lightNeutral0,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      boxShadow: '0px 0px 32px rgba(40, 40, 40, 0.32)',
      zIndex: 3,
    },
    buttonsContainer: {
      height: '168px',
      alignItems: 'center',
      paddingTop: '32px',
      paddingBottom: '24px',
    },
    cancelContainer: {
      borderTopWidth: 1,
      borderColor: colors.grayScale.lightNeutral60,
      height: '80px',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cancelText: {
      ...fonts.bold,
      color: colors.core.blue,
    },
    backdrop: {
      position: 'fixed',
      width: '100%',
      height: '100vh',
      top: 0,
      left: 0,
      opacity: 0.5,
      zIndex: 0,
    }
  });

  const {
    noteSettingsMenu: { hideNoteSettingsMenu },
    confirmDeleteNoteDialog: { showConfirmDeleteNoteDialog },
    noteId: { noteId },
    noteTitle: { noteTitle },
    noteStatus: { noteStatus },
    noteAssociatedSite: { noteAssociatedSite },
  } = useStore();

  const navigate = useNavigate();

  const noteSettingsButtonDataLayer = EditNoteButtonTagManagerArgs(noteTitle, noteStatus, noteAssociatedSite);
  const noteDeleteButtonDataLayer = DeleteNoteTagManagerArgs();
  const noteCancelButtonDataLayer = CancelNoteMenuTagManagerArgs();

  return (
    <>
      <View style={style.base}>
        <View style={style.buttonsContainer}>
          <Button onPress={() => { pushTagManagerLayer(noteSettingsButtonDataLayer); navigate(`/notes/${noteId}/edit`); hideNoteSettingsMenu(); }} buttonText="Note settings" iconName="settingsIcon" type="primary" iconHeight={24} iconWidth={24} style={{width: '193px'}} />
          <Button onPress={() => { pushTagManagerLayer(noteDeleteButtonDataLayer); showConfirmDeleteNoteDialog(); hideNoteSettingsMenu(); }} buttonText="Delete note" iconName="deleteIcon" type="secondary" iconHeight={24} iconWidth={24} style={{width: '179px', marginTop: '16px'}} />
        </View>

        <TouchableOpacity style={style.cancelContainer} onPress={() => { pushTagManagerLayer(noteCancelButtonDataLayer); hideNoteSettingsMenu(); }}>
          <Text style={style.cancelText}>Cancel</Text>
        </TouchableOpacity>
      </View>

      <TouchableOpacity style={style.backdrop} onPress={() => { pushTagManagerLayer(noteCancelButtonDataLayer); hideNoteSettingsMenu(); }} />
    </>
  )
}

export default NoteMenu;