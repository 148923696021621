import { AxiosError } from 'axios';
import { MutationFunction, useMutation } from 'react-query';


export function usePost<TData, TVariables, TError = AxiosError>(
  fn: MutationFunction<TData, TVariables>,
  onSuccess?: (data: TData) => void | Promise<void>,
  onError?: (error: TError) => void | Promise<void>
) {

  const mutation = useMutation<TData, TError, TVariables>(fn, {
    onMutate: () => {
      // sessionRefresh(); check if session is refreshed
    },
    onError: (error) => {
      onError ? onError(error) : console.log(error);
    },
    onSuccess: (data) => {
      onSuccess ? onSuccess(data) : console.log(data);
    },
  });
  return mutation;
}
