import { makeAutoObservable } from 'mobx';
import { Index } from 'models/Index/Index.model';

export class Indexes {
  indexes = [] as Index[];

  constructor() {
    makeAutoObservable(this);
  }

  setIndexes = (indexes: Index[]) => {
    this.indexes = indexes;
  };

  getIndex = (indexId: string) => {
    console.log(this.indexes);
    return this.indexes.find((i: Index) => i.guid === indexId);
  };
}
