import Image from "components/parts/Image";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useLocation } from "react-router-dom";
import { useNavigate } from "utils/useNavigate";
import { colors, fonts } from "utils/constants";
import { useStore } from "utils/store";
import { LogoutTagManagerArgs, MenuDashboardTagManagerArgs, MenuNotesTagManagerArgs, MenuPublishTagManagerArgs, pushTagManagerLayer } from "utils/tagManagerArgs";

interface MenuProps {

}

const Menu = ({ }: MenuProps) => {
  const style = StyleSheet.create({
    base: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      height: '80px',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.grayScale.lightNeutral0,
      zIndex: 1,
      filter: 'drop-shadow(0px -3px 5px rgba(40, 40, 40, 0.16))',
    },
    menuTab: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: '0.9em',
      flex: 1,
      height: '80px',
    },
    activeTab: {
      borderTopWidth: 4,
      borderColor: colors.core.cyan,
      height: '80px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: '0.9em',
      flex: 1,
    },
    tabText: {
      ...fonts.small,
      color: colors.core.blue,
      marginTop: '0.8em',
    }
  });

  const {
    confirmSignoutDialog: { showConfirmSignoutDialog },
  } = useStore();

  const navigate = useNavigate();

  const location = useLocation().pathname;

  const illegalPaths = [
    '/login',
    '/report',
    '/public',
    '/indexCreationWizard',
    '/editIndex',
    '/detail',
  ];

  const isIllegalPath = illegalPaths.some(path => location.startsWith(path));

  const isDashboardPath = location === '/';
  const isPublishPath = location === '/publish';
  const isNotesPath = location === '/notes';
  const isHelpPath = location === '/help';

  return !isIllegalPath ? (
    <View style={style.base}>
      <TouchableOpacity style={isDashboardPath ? style.activeTab : style.menuTab} onPress={() => { pushTagManagerLayer(MenuDashboardTagManagerArgs()); navigate('', 'Dashboard'); }}>
        <Image imageName="homeIcon" htmlImg={true} htmlStyle={{ width: '34px', height: '30px' }} />
        <Text style={style.tabText}>Dashboard</Text>
      </TouchableOpacity>
      <TouchableOpacity style={isNotesPath ? style.activeTab : style.menuTab} onPress={() => { pushTagManagerLayer(MenuNotesTagManagerArgs()); navigate('/notes', 'Notes'); }}>
        <Image imageName="notesIcon" htmlImg={true} htmlStyle={{ width: '30px', height: '28px' }} />
        <Text style={style.tabText}>Notes</Text>
      </TouchableOpacity>
      <TouchableOpacity style={isPublishPath ? style.activeTab : style.menuTab} onPress={() => { pushTagManagerLayer(MenuPublishTagManagerArgs()); navigate('/publish', 'Publish') }}>
        <Image imageName="eyeIcon" htmlImg={true} htmlStyle={{ width: '34px', height: '27px' }} />
        <Text style={style.tabText}>Publish</Text>
      </TouchableOpacity>
      <TouchableOpacity style={isHelpPath ? style.activeTab : style.menuTab} onPress={() => { pushTagManagerLayer(LogoutTagManagerArgs()); showConfirmSignoutDialog(); }}>
        <Image imageName="signoutIcon" htmlImg={true} htmlStyle={{ width: '26px', height: '26px' }} />
        <Text style={style.tabText}>Log out</Text>
      </TouchableOpacity>
    </View>
  ) : null;
}

export default Menu;