type StorageState = {
  key: string;
  state?: any;
  storage?: 'session' | 'local';
};

export const loadState = ({ key, storage = 'session' }: StorageState) => {
  let serializedState;

  switch (storage) {
    case 'session':
      serializedState = sessionStorage.getItem(key);
      if (serializedState === null) {
        return null;
      }
      return JSON.parse(serializedState);

    case 'local':
      serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        return null;
      }
      return JSON.parse(serializedState);

    default:
      return null;
  }
};

export const saveState = ({ key, state, storage = 'session' }: StorageState) => {
  try {
    let serializedState = JSON.stringify(state);
    switch (storage) {
      case 'session':
        sessionStorage.setItem(key, serializedState);
        break;

      case 'local':
        localStorage.setItem(key, serializedState);
        break;

      default:
        break;
    }
  } catch (error) {
    console.log(error);
  }
};

export const clearState = ({ key, storage = 'session' }: StorageState) => {
  switch (storage) {
    case 'session':
      sessionStorage.removeItem(key);
      break;

    case 'local':
      localStorage.removeItem(key);
      break;

    default:
      break;
  }
};
