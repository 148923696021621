import Image from 'components/parts/Image';
import { ForwardedRef, forwardRef, useState } from 'react';
import { NativeSyntheticEvent, StyleSheet, TextInput, TextInputChangeEventData, View, ViewStyle } from 'react-native';
import { colors, globalStyles } from 'utils/constants';

interface TextInputProps {
  value: string;
  setValue?: (value: string) => void;
  placeholder?: string;
  maxLength?: number;
  error?: boolean;
  style?: ViewStyle;
  disabled?: boolean;
  secureTextEntry?: boolean;
  adornment?: string;
  height?: number | 'auto';
}

const CustomTextInput = forwardRef(
  (
    { adornment, value, setValue, placeholder, maxLength, error, style, secureTextEntry = false, disabled, height = 'auto' }: TextInputProps,
    ref: ForwardedRef<TextInput>
  ) => {
    const [active, setActive] = useState(false);

    const styles = StyleSheet.create({
      base: {
        ...globalStyles.textInput,
        borderColor: error ? colors.highlights.red : active ? colors.core.cyan : colors.core.blue,
        backgroundColor: error ? colors.grayScale.lightNeutral0 : active ? colors.core.cyan10 : colors.grayScale.lightNeutral0,
        flexGrow: 2,
        height: height === 'auto' ? undefined : height,
        paddingTop: '0.4em',
        paddingBottom: '0.4em', 
        ...style,
      },
    });

    return (
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: height === 'auto' ? undefined : height }}>
        <TextInput
          style={styles.base}
          value={value}
          onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => {
            if (setValue) setValue(e.nativeEvent.text);
          }}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          secureTextEntry={secureTextEntry}
          placeholder={placeholder}
          maxLength={maxLength ? maxLength : undefined}
          ref={ref}
          disabled={disabled}
          multiline={height !== 'auto'}
        />
        {!!adornment && (
          <Image
            imageName={adornment}
            style={{ position: 'absolute', bottom: '12.5px', right: '15px', marginLeft: '8px', width: '24px', height: '24px' }}
          />
        )}
      </View>
    );
  }
);

export default CustomTextInput;
